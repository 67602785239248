import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const DEFAULT_PROPS = {
  actions: [],
  config: {},
  events: {},
  headers: [],
  rows: [],
  serverSide: false,
};

export const PROP_TYPES = {
  actions: array,
  config: shape({
    download: bool,
    filter: bool,
    offsetTop: number,
    pagination: bool,
    replaceDownload: bool,
    responsive: oneOf([
      'simple',
      'standard',
      'vertical',
    ]),
    storageKey: string,
  }),
  customSearch: func,
  events: shape({
    onDownload: func,
    onFilterChange: func,
    onFilterChipClose: func,
    onFilterConfirm: func,
    onTableChange: func,
    onTableInit: func,
    onViewColumnsChange: func,
  }),
  headers: arrayOf(shape({
    label: string,
    format: string,
    name: string.isRequired,
    options: arrayOf(shape({
      id: oneOfType([
        number,
        string,
      ]),
      name: string,
    })),
    type: string,
    variant: shape({
      type: oneOf([
        'component',
      ]),
      mapping: oneOf([
        'label',
        'link',
      ]),
      props: oneOfType([
        object,
        func,
      ]),
    }),
  })),
  rows: array,
  serverSide: bool,
  title: string,
};

export const TEXT_LABELS = {
  body: {
    noMatch: 'Lo sentimos, no se encontraron registros coincidentes',
    toolTip: 'Ordenar',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Restaurar',
  },
  pagination: {
    displayRows: 'de',
    jumpToPage: 'Ir a la página',
    next: 'Siguiente',
    previous: 'Anterior',
    rowsPerPage: 'Filas por página',
  },
  selectedRows: {
    text: 'filas seleccionadas',
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
  },
  toolbar: {
    downloadCsv: 'Descargar',
    filterTable: 'Filtrar tabla',
    print: 'Imprimir',
    search: 'Buscar',
    viewColumns: 'Ver columnas',
  },
  viewColumns: {
    title: 'Ver columnas',
    titleAria: 'Mostrar/Ocultar columnas de la tabla',
  },
};
