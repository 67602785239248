import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

import backArrow from 'assets/svg/return.svg';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none !important',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'inline-flex',

  },
  arrow: {
    paddingTop: '0px',
  },
  text: {
    color: '#213134',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontFamily: 'Arial',
    fontSize: '14px',
    lineHeight: '18px',
    textDecoration: 'none !important',
  },
  positionreturn: {
    display: 'flex',
    float: 'left',
    height: 'auto',
    paddingTop: '20px',
    width: '100%',
  },
}));

function ButtonReturn({ link, children }) {

  const classes = useStyles();

  return (
    <RouterLink to={link} className={classes.link}>
      <img className={classes.arrow} src={backArrow} alt='' />
      <Typography
        children={children}
        className={classes.text}
      />
    </RouterLink>
  );
};

ButtonReturn.defaultProps = {
  children: 'Regresar',
};

ButtonReturn.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string,
};

export default ButtonReturn;
