import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CustomSelect from '../components/CustomSelect';
import { DEFAULT_LOCALE_EN } from '../locale';
import { classNames } from '../utils';
import { UNITS } from '../constants';

function Minutes(props) {
  const {
    value,
    setValue,
    locale,
    className,
    disabled,
    readOnly,
    leadingZero,
    clockFormat,
    period,
  } = props;
  const internalClassName = useMemo(() => classNames({
    'react-js-cron-field': true,
    'react-js-cron-minutes': true,
    [`${className}-field`]: !!className,
    [`${className}-minutes`]: !!className,
  }), [className]);
  return React.createElement('div', {
    className: internalClassName,
  }, period === 'hour' ? locale.prefixMinutesForHourPeriod !== '' && React.createElement('span', null, locale.prefixMinutesForHourPeriod || DEFAULT_LOCALE_EN.prefixMinutesForHourPeriod) : locale.prefixMinutes !== '' && React.createElement('span', null, locale.prefixMinutes || DEFAULT_LOCALE_EN.prefixMinutes), React.createElement(CustomSelect, {
    placeholder: period === 'hour' ? locale.emptyMinutesForHourPeriod || DEFAULT_LOCALE_EN.emptyMinutesForHourPeriod : locale.emptyMinutes || DEFAULT_LOCALE_EN.emptyMinutes,
    value: value,
    unit: UNITS[0],
    setValue: setValue,
    locale: locale,
    className: className,
    disabled: disabled,
    readOnly: readOnly,
    leadingZero: leadingZero,
    clockFormat: clockFormat,
    period: period,
  }), period === 'hour' && locale.suffixMinutesForHourPeriod !== '' && React.createElement('span', null, locale.suffixMinutesForHourPeriod || DEFAULT_LOCALE_EN.suffixMinutesForHourPeriod));
}

Minutes.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  locale: PropTypes.shape({
    emptyMinutes: PropTypes.string,
    emptyMinutesForHourPeriod: PropTypes.string,
    prefixMinutes: PropTypes.string,
    prefixMinutesForHourPeriod: PropTypes.string,
    suffixMinutesForHourPeriod: PropTypes.string,
  }),
  className: PropTypes.any,
  disabled: PropTypes.any,
  readOnly: PropTypes.any,
  leadingZero: PropTypes.any,
  clockFormat: PropTypes.any,
  period: PropTypes.any,
};

export default Minutes;
