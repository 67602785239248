import { useEffect, useState } from 'react';
import { node, string } from 'prop-types';

import { LoadingScreen } from 'components';
import { hasPermissions } from 'utils/permissions';
import Error403View from 'views/Errors/Error403';

function PermissionGuard({ permission, children }) {
  const [node, setNode] = useState(<LoadingScreen />);
  const errorComponent = permission === 'dashboard.select.listDashboard'
    ? <></>
    : <Error403View permission={permission} />;

  useEffect(() => {
    const canAccess = (permission === undefined) || hasPermissions(permission);
    const Component = canAccess
      ? children
      : errorComponent;

    setNode(Component);
  }, []);

  return node;
};

PermissionGuard.propTypes = {
  children: node,
  permission: string,
};

export default PermissionGuard;
