import {
  PEOPLE_TYPES_FAILURE,
  PEOPLE_TYPES_FETCHING,
  PEOPLE_TYPES_SEARCH,
  PEOPLE_TYPES_SUCCESS,
} from 'redux/constants/peopleTypes';

const initialState = {
  allItems: [],
  items: [],
  total: 0,
  isFetching: false,
  error: null,
};

function peopleTypesReducer(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_TYPES_FETCHING:
      return {
        ...state,
        isFetching: true,
      };

    case PEOPLE_TYPES_SUCCESS:
      return {
        ...state,
        allItems: action.items,
        items: action.items,
        total: action.total,
        isFetching: false,
      };
    case PEOPLE_TYPES_SEARCH:
      const newItems = state.allItems.reduce((pV, cV) => {
        if (
          cV.values.some((el) => {
            try {
              if (el.toLowerCase().includes(action.query.toLowerCase())) {
                return true;
              }
            } catch (err) {
              console.error(err);
            }
            return false;
          })
        ) {
          pV.push(cV);
        }
        return pV;
      }, []);
      return {
        ...state,
        items: newItems,
      };
    case PEOPLE_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default peopleTypesReducer;
