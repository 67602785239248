import PropTypes from 'prop-types';

function GoogleMapsControl(props) {
  // const {
  //   error,
  //   events,
  //   name,
  //   onChange,
  // } = props;

  // const handleChange = (value) => {
  //   const target = { name, value };
  //   onChange({ target });

  //   if (events?.onChange) {
  //     events.onChange(target);
  //   }
  // };

  return (<div>
  </div>);
}

GoogleMapsControl.propTypes = {
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
  }),
};

export default GoogleMapsControl;
