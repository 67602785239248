import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  translationEN,
  translationES,
  translationZH,
} from './translations';

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
};

const availableLanguages = Object.keys(resources);
const [defaultLanguage] = availableLanguages;
const fallbackLng = [defaultLanguage];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

function getCurrentLanguaje() {
  return localStorage
    .getItem('i18nextLng') || defaultLanguage;
}

function setMomentLocale() {
  try {
    require(`moment/locale/${getCurrentLanguaje()}`);
  } catch (error) {
    console.error(error);
  }
}

export {
  availableLanguages,
  defaultLanguage,
  getCurrentLanguaje,
  setMomentLocale,
};
