import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {},
  firstButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  secondButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  centeredTitle: {
    textAlign: 'center',
    '& h2': {
      fontSize: '20px',
    },
  },
  borderModal: {
    borderRadius: '20px!important',
    '& .MuiDialog-paper': {
      borderRadius: '20px!important',
    }, 
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '500px!important',
    },
    '& .MuiDialogActions-root': {
      padding: '0 !important',
    },
  },
}));
