import {
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

const validateSchema = object().shape({
  max: number(),
  min: number(),
  required: boolean().default(false),
});

export const configSchema = object()
  .shape({
    disabled: boolean()
      .default(false),
    displayname: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    displaytype: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    name: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    options: array(),
    score: number()
      .integer()
      .positive(),
    type: string()
      .default('string')
      .required(MESSAGES.REQUIRED),
    validate: validateSchema,
    value: mixed()
      .optional(),
  });

export const operationSchema = object().shape({
  id: number()
    .integer()
    .positive()
    .optional(),
  name: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  operation: number()
    .integer()
    .positive()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER),
  product: number()
    .integer()
    .positive(),
  template: number()
    .integer()
    .positive()
    .transform((value, originalValue) => isNaN(originalValue) ? undefined : value)
    .nullable(),
});

export const actionsSchema = object().shape({
  name: string()
    .trim()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.TYPE_ERROR_STRING),
  age: number()
    .integer()
    .positive()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.TYPE_ERROR_NUMBER),
});

export const paymentConfigSchema = object().shape({
  id: number(),
  company: number().required(),
  evaluations: object()
    .shape({
      scoring: array(),
    })
    .default({
      scoring: [],
    }),
  options: object()
    .shape({
      evaluations: boolean().default(false),
      options: array(),
    })
    .default({
      options: [],
    }),
  product: number().required(),
});

export const templateSchema = object().shape({
  displayname: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  displaytype: string()
    .trim()
    .default('text')
    .required(MESSAGES.REQUIRED),
  field: number()
    .integer()
    .positive()
    .optional(),
  format: string()
    .optional(),
  name: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  options: array(),
  score: number()
    .integer()
    .positive(),
  type: string()
    .trim()
    .default('string')
    .required(MESSAGES.REQUIRED),
  validate: validateSchema,
});

export const productsFormSchema = object().shape({
  id: number()
    .integer()
    .positive()
    .optional(),
  name: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  description: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  product_type: number()
    .integer()
    .positive(),
  config: array()
    .of(configSchema)
    .default([]),
  tabs: object()
    .default({}),
  type: string()
    .oneOf([
      'assets',
      'liabilities',
    ])
    .required(MESSAGES.REQUIRED),
  currency: number()
    .integer()
    .positive()
    .required(MESSAGES.REQUIRED),
  operations: array()
    .of(operationSchema)
    .default([]),
  paymentConfig: array()
    .of(paymentConfigSchema)
    .default([]),
  template: array(templateSchema)
    .default([]),
});

export const productSimulator = object().shape({
  amount: number()
    .positive()
    .required(MESSAGES.REQUIRED),
  product: number()
    .integer()
    .positive(),
  properties: object().shape({
    amount_commission_opening: number()
      .default(0),
    disbursed_date: date()
      .default(new Date()),
    start_date: date()
      .default(new Date()),
    principal_disbursed: number(),
  }),
});
export const defaultProductFormSchema = object().shape({
  product: number()
    .required(MESSAGES.REQUIRED),
  currency: number()
    .required(MESSAGES.REQUIRED),
  name: string()
    .trim()
    .default(''),
  description: string()
    .trim()
    .required(MESSAGES.REQUIRED),
});

export const actionOperationSchema = object().shape({
  sql_cmd: number()
    .integer()
    .positive()
    .required(MESSAGES.REQUIRED),
  field: number()
    .integer()
    .optional(),
  dst_account: number()
    .optional(),
});
