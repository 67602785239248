import {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import { DynamicForm } from 'components';
import { TABLES_ITEMS_PER_PAGE } from 'settings';

import { DEFAULT_PROPS, PROP_TYPES } from './constants';
import Filters from './Filters';
import useStyles from './styles';
import { DateControl } from '../DynamicForm/controls';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.action.focus} !important`,
    },
  },
}))(TableRow);

function DataTablev3(props) {
  const {
    actions,
    filters,
    footers,
    footerSeparator,
    loadData = props.getDataAction,
    headers,
    rows,
    pagination = {},
    searchAction,
    searchActionByDate,
    mt,
    onDeleteFilterDate,
    selectable,
    size,
    stickyHeader,
    subTitle,
    title,
    total,
    offsetTop,
  } = props;

  const {
    disabled: removePagination = false,
    perPageOptions = TABLES_ITEMS_PER_PAGE,
  } = pagination;
  const history = useHistory();
  const containerRef = useRef();
  const classes = useStyles();
  const [limit, setLimit] = useState(perPageOptions[0]);
  const [page, setPage] = useState(1);
  const [maxHeight, setMaxHeight] = useState(250);
  const [query, setQuery] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.datatable',
  });
  const [open, setOpen] = useState({});

  //console.log('rows=>', rows);

  const handleToggleCollapse = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const hasChildren = (parentId, rows) => {
    return rows.some((row) => row.data.config?.credit_line_id === parentId.toString());
  };

  const handleSelectAllItems = (event) => {
    setSelectedItems(event.target.checked
      ? rows.map((item) => item.id)
      : []);
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId),
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setQuery('');
    setPage(newPage + 1);

    loadData({
      page: newPage + 1,
      limit,
    });
  };

  const handleLimitChange = (event) => {
    setQuery('');
    const newLimit = event.target.value;
    setLimit(newLimit);
    setPage(1);
    loadData && loadData({
      page: 1,
      limit: newLimit,
    });
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    searchAction(event.target.value);
  };

  const handleQueryChangeByDate = (event) => {
    setQuery(event.target.value);
    searchActionByDate(event.target.value);
  };

  const selectedSomeItems =
    selectedItems &&
    selectedItems.length > 0 &&
    selectedItems.length < rows &&
    rows.length;
  const selectedAllItems =
    selectedItems && selectedItems.length === rows && rows.length;

  useEffect(() => {
    return history.listen(() => {
      setPage(1);
    });
  }, [history]);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const { top: elementTop } = containerRef.current.getBoundingClientRect();
    const height = windowHeight - elementTop - offsetTop;

    setMaxHeight(height);
  }, []);

  return (
    <Box mt={mt} className={classes.root}>

      <Filters
        filters={filters}
        query={query}
        searchAction={searchAction}
        handleQueryChange={handleQueryChange}
      />

      {!!searchActionByDate && <Paper className={classes.filterCard}>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={4} xs={6}>
            <DateControl
              fullWidth
              label={t('search')}
              size="small"
              variant="outlined"
              value={query}
              onChange={handleQueryChangeByDate}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              children="Eliminar filtro"
              onClick={() => onDeleteFilterDate()}
              size="large"
              style={{
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Paper>}

      <Card>
        {title && (<>
          <CardHeader
            title={title}
            subheader={subTitle}
          />
          <Divider />
        </>)}
        <PerfectScrollbar>
          <Box>
            <Paper className={classes.root}>
              <TableContainer
                ref={containerRef}
                style={{
                  maxHeight: stickyHeader ? maxHeight : undefined,
                }}
              >
                <Table stickyHeader={stickyHeader} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {selectable && (
                        <TableCell padding="checkbox" size={size}>
                          <Checkbox
                            checked={selectedAllItems}
                            indeterminate={selectedSomeItems}
                            onChange={handleSelectAllItems}
                          />
                        </TableCell>
                      )}
                      {headers.map((head, index) => {
                        const align = head?.align ?? 'center';
                        let cellValue = head;

                        if (typeof head === 'object') {
                          cellValue = head.label;
                        }

                        return (<TableCell
                          align={align}
                          children={cellValue}
                          key={index}
                          size={size}
                        />);
                      })}
                      {actions && (<TableCell
                        align="center"
                        style={{
                          minWidth: `${actions.length * 2 + 2}em`,
                        }}
                        children={t('actions')}
                        size={size}
                      />)}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows && rows.map((item, index) => {
                      const isParentOrNoChildren = !item.data.config?.credit_line_id || item.data.config?.credit_line_id === item.id.toString();
                      if (!isParentOrNoChildren) {
                        return null;
                      }
                      const isItemSelected = selectedItems.includes(item.id);
                      const isOpen = open[index] || false;
                      const collapseRows = rows.filter((row) => row.data.config?.credit_line_id === item.id.toString());
                      const childrenExist = hasChildren(item.data.id, rows);
                      return (
                        <Fragment key={index}>
                          <StyledTableRow key={index} hover onClick={() => handleToggleCollapse(index)}>
                            {selectable && (
                              <TableCell padding="checkbox" size={size}>
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) =>
                                    handleSelectOneItem(event, item.id)
                                  }
                                  value={isItemSelected}
                                />
                              </TableCell>
                            )}

                            {item.values.map((colValue, iCell) => {
                              let cell = {
                                children: colValue,
                              };

                              if (typeof colValue === 'object' && colValue !== null && !colValue.props) {
                                if (colValue.field) {
                                  cell.children = (<DynamicForm
                                    fields={colValue.field}
                                    size="small"
                                  />);
                                } else {
                                  cell = {
                                    align: colValue.align || 'left',
                                    children: colValue.label,
                                    colSpan: colValue.colSpan,
                                  };
                                }
                              }

                              return (
                                <TableCell
                                  align="left"
                                  style={item?.data?.style}
                                  key={iCell}
                                  size={size}
                                  {...cell}
                                />
                              );
                            })}

                            {Array.isArray(actions) && (
                              <TableCell
                                align="center"
                                size={size}
                                style={item?.data?.style}
                              >
                                {actions.map((action, key) => {
                                  const { fn = () => { } } = action;
                                  let {
                                    color,
                                    disabled,
                                    hidden,
                                    icon,
                                    label,
                                    title,
                                  } = action;

                                  if (typeof disabled === 'function') {
                                    disabled = disabled(item, index);
                                  }

                                  if (disabled) {
                                    color = 'default';
                                  } else if (typeof color === 'function') {
                                    color = color(item, index);
                                  }

                                  if (typeof hidden === 'function') {
                                    hidden = hidden(item, index);
                                  }

                                  if (typeof icon === 'function') {
                                    icon = icon(item, index);
                                  }

                                  if (typeof label === 'function') {
                                    label = label(item, index);
                                  }

                                  if (typeof title === 'function') {
                                    title = title(item, index);
                                  }

                                  const Title = title ? Tooltip : Fragment;
                                  return !hidden ? (
                                    <Title key={key} title={title}>
                                      {action.label
                                        ? (<Button
                                          children={label}
                                          className={classes[`${color}Bg`]}
                                          disabled={disabled}
                                          onClick={() => fn(item, index)}
                                          size="small"
                                          style={{
                                            marginRight: '0.4em',
                                            marginLeft: '0.4em',
                                          }}
                                          startIcon={icon}
                                          variant="contained"
                                        />)
                                        : (<IconButton
                                          disabled={disabled}
                                          onClick={() => fn(item, index)}
                                          size="small"
                                          variant="outlined"
                                        >
                                          <SvgIcon
                                            children={icon}
                                            className={classes[color]}
                                            fontSize="small"
                                          />
                                        </IconButton>)}
                                    </Title>) : null;
                                })}
                                {childrenExist && (
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                  >
                                    {!isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                  </IconButton>
                                )}
                              </TableCell>
                            )}
                          </StyledTableRow>
                          <TableRow>
                            <TableCell
                              style={
                                {
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  paddingRight: childrenExist && 0,
                                  paddingLeft: childrenExist && 0,
                                }}
                              colSpan={
                                headers.length +
                                (selectable ? 1 : 0) +
                                (actions ? 1 : 0)
                              }
                            >
                              <Collapse in={!isOpen} timeout="auto" unmountOnExit>
                                <Table id={'table-collapse'} aria-label="sticky table">
                                  <TableBody id={'table-body-collapse'}>
                                    {collapseRows.map((collapseItem, collapseIndex) => {
                                      //if (index === 1) {
                                      //  return null;
                                      //}
                                      const isCollapseItemSelected = selectedItems.includes(collapseItem.id);
                                    
                                      return (
                                        <Fragment key={index}>
                                          <StyledTableRow key={index} hover>
                                            {selectable && (
                                              <TableCell padding="checkbox" size={size}>
                                                <Checkbox
                                                  checked={isCollapseItemSelected}
                                                  onChange={(event) =>
                                                    handleSelectOneItem(event, collapseItem.id)
                                                  }
                                                  value={isCollapseItemSelected}
                                                />
                                              </TableCell>
                                            )}
                                            {collapseItem.values.map((colValue, iCell) => {
                                              let cell = {
                                                children: colValue,
                                              };
                                            
                                              if (typeof colValue === 'object' && colValue !== null && !colValue.props) {
                                                if (colValue.field) {
                                                  cell.children = (<DynamicForm
                                                    fields={colValue.field}
                                                    size="small"
                                                  />);
                                                } else {
                                                  cell = {
                                                    align: colValue.align || 'left',
                                                    children: colValue.label,
                                                    colSpan: colValue.colSpan,
                                                  };
                                                }
                                              }
                                            
                                              return (
                                                <TableCell
                                                  align="left"
                                                  style={collapseItem?.data?.style}
                                                  key={iCell}
                                                  size={size}
                                                  {...cell}
                                                />
                                              );
                                            })}
                                            {Array.isArray(actions) && (
                                              <TableCell
                                                align="center"
                                                size={size}
                                                style={collapseItem?.data?.style}
                                              >
                                                {actions.map((action, key) => {
                                                  const { fn = () => { } } = action;
                                                  let {
                                                    color,
                                                    disabled,
                                                    hidden,
                                                    icon,
                                                    label,
                                                    title,
                                                  } = action;
                                                
                                                  if (typeof disabled === 'function') {
                                                    disabled = disabled(collapseItem, index);
                                                  }
                                                
                                                  if (disabled) {
                                                    color = 'default';
                                                  } else if (typeof color === 'function') {
                                                    color = color(collapseItem, index);
                                                  }
                                                
                                                  if (typeof hidden === 'function') {
                                                    hidden = hidden(collapseItem, index);
                                                  }
                                                
                                                  if (typeof icon === 'function') {
                                                    icon = icon(collapseItem, index);
                                                  }
                                                
                                                  if (typeof label === 'function') {
                                                    label = label(collapseItem, index);
                                                  }
                                                
                                                  if (typeof title === 'function') {
                                                    title = title(collapseItem, index);
                                                  }
                                                
                                                  const Title = title ? Tooltip : Fragment;
                                                  return !hidden ? (
                                                    <Title key={key} title={title}>
                                                      {action.label
                                                        ? (<Button
                                                          children={label}
                                                          className={classes[`${color}Bg`]}
                                                          disabled={disabled}
                                                          onClick={() => fn(collapseItem, index)}
                                                          size="small"
                                                          style={{
                                                            marginRight: '0.4em',
                                                            marginLeft: '0.4em',
                                                          }}
                                                          startIcon={icon}
                                                          variant="contained"
                                                        />)
                                                        : (<><IconButton
                                                          disabled={disabled}
                                                          onClick={() => fn(collapseItem, index)}
                                                          size="small"
                                                          variant="outlined"
                                                        >
                                                          <SvgIcon
                                                            children={icon}
                                                            className={classes[color]}
                                                            fontSize="small"
                                                          />
                                                        </IconButton>
                                                        <Box width={30} display="inline-block" />
                                                        </>)}
                                                    </Title>) : null;
                                                })}
                                              </TableCell>
                                            )}
                                          </StyledTableRow>
                                        </Fragment>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>

                  {footers && (<TableFooter>

                    {footerSeparator && (<TableRow>
                      <TableCell
                        className={classes.footerSeparator}
                        size="small" colSpan={6} />
                    </TableRow>)}

                    <TableRow>
                      {footers.map((footer, index) => {
                        let align = footer.align || 'center';
                        let cellValue = footer;

                        if (typeof footer === 'object' && !footer?.props) {
                          cellValue = footer.label || null;

                          if (footer.type === 'number') {
                            align = 'right';
                          }
                        }

                        return (<TableCell
                          align={align}
                          children={cellValue}
                          key={index}
                          size={size}
                        />);
                      })}
                    </TableRow>
                  </TableFooter>)}
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </PerfectScrollbar>

        {!removePagination && (
          <TablePagination
            backIconButtonText={t('previous_page')}
            component="div"
            count={total}
            labelRowsPerPage={t('rows_per_page')}
            nextIconButtonText={t('next_page')}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={perPageOptions}
          />
        )}
      </Card>
    </Box>
  );
}

DataTablev3.defaultProps = DEFAULT_PROPS;

DataTablev3.propTypes = PROP_TYPES;

export default DataTablev3;
