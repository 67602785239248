import { useContext } from 'react';

import SettingsContext from '../context/SettingsContext';

function useSettings() {
  const context = useContext(SettingsContext);

  return context;
}

export default useSettings;
