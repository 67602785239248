import accountMovements from './accountsMovements.json';
import banks from './banks.json';
import currencies from './currencies.json';
import fieldsTypes from './fieldsTypes.json';
import notificationChannels from './notificationChannels.json';

export const STATIC_DATA = {
  ACCOUNT_MOVEMENTS: 'account_movements',
  BANKS: 'banks',
  CURRENCIES: 'currencies',
  FIELD_TYPES: 'field_types',
  NOTIFICATION_CHANNELS: 'notification_channels',
};

export const getStaticData = (json, filter = {}) => {
  const data = {
    [STATIC_DATA.ACCOUNT_MOVEMENTS]: accountMovements,
    [STATIC_DATA.BANKS]: banks,
    [STATIC_DATA.CURRENCIES]: currencies,
    [STATIC_DATA.FIELD_TYPES]: fieldsTypes,
    [STATIC_DATA.NOTIFICATION_CHANNELS]: notificationChannels,
  }[json];

  if ('id' in filter) {
    return data.find((_) => _.id === filter.id);
  }

  const filtered = data.filter((_) => {
    for (const key in filter) {
      if (_[key] === undefined || _[key] !== filter[key]) {
        return false;
      }
    }
    return true;
  });

  return filtered;
};
