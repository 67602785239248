import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

function FormErrorNotification({ debug, onValidationError }) {
  const {
    errors,
    isValid,
    isValidating,
    isSubmitting,
    values,
  } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      onValidationError(errors);
    }
  }, [isSubmitting, isValid, isValidating]);

  return (debug
    ? <>
      <hr />
      <pre
        children={JSON.stringify({
          errors,
          values,
        }, null, '  ')}
      />
      <hr />
    </>
    : null);
};

FormErrorNotification.propTypes = {
  debug: PropTypes.bool,
  onValidationError: PropTypes.func,
};

export default FormErrorNotification;
