import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'layouts/DashboardLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const demoRoutes = {
  path: '/_demo',
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: '/_demo/forms',
      component: lazy(() => import('views/_Demo/Forms')),
    },
    {
      exact: true,
      path: '/_demo/identity',
      component: lazy(() => import('views/_Demo/Identity')),
    },
    {
      exact: true,
      path: '/_demo/payment_methods',
      component: lazy(() => import('views/_Demo/PaymentMethods')),
    },
    {
      exact: true,
      path: '/_demo/table',
      component: lazy(() => import('views/_Demo/Table')),
    },
    error404,
  ],
};

export default demoRoutes;
