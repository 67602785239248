import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CustomSelect from '../components/CustomSelect';
import { DEFAULT_LOCALE_EN } from '../locale';
import { classNames } from '../utils';
import { UNITS } from '../constants';

function ownKeys(object, enumerableOnly) {
  const keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) { let symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys;
}

function _objectSpread(target) {
  for (let i = 1; i < arguments.length; i++) {
    const source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); }
  } return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else { obj[key] = value; } return obj;
}

function Months(props) {
  const {
    value,
    setValue,
    locale,
    className,
    humanizeLabels,
    disabled,
    readOnly,
    period,
  } = props;
  const optionsList = locale.months || DEFAULT_LOCALE_EN.months;
  const internalClassName = useMemo(() => classNames({
    'react-js-cron-field': true,
    'react-js-cron-months': true,
    [`${className}-field`]: !!className,
    [`${className}-months`]: !!className,
  }), [className]);
  return React.createElement('div', {
    className: internalClassName,
  }, locale.prefixMonths !== '' && React.createElement('span', null, locale.prefixMonths || DEFAULT_LOCALE_EN.prefixMonths), React.createElement(CustomSelect, {
    placeholder: locale.emptyMonths || DEFAULT_LOCALE_EN.emptyMonths,
    optionsList: optionsList,
    grid: false,
    value: value,
    unit: _objectSpread(_objectSpread({}, UNITS[3]), {}, {
      alt: locale.altMonths || DEFAULT_LOCALE_EN.altMonths,
    }),
    setValue: setValue,
    locale: locale,
    className: className,
    humanizeLabels: humanizeLabels,
    disabled: disabled,
    readOnly: readOnly,
    period: period,
  }));
}

Months.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  locale: PropTypes.any,
  className: PropTypes.any,
  humanizeLabels: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  period: PropTypes.any,
};

export default Months;
