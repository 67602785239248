import {
  SET_TABLE_DATA,
  HAS_ERROR,
  IS_LOADING,
  READ_ALL,
  SET_LAST_UPDATE,
} from 'redux/constants/accions';
import { TABLES_ITEMS_PER_PAGE } from 'settings';

export const initialState = {
  error: null,
  isLoading: false,
  items: {
    rows: [],
    total: 0,
  },
  lastUpdate: null,
  table: {
    headers: [],
    pagination: {
      perPageOptions: TABLES_ITEMS_PER_PAGE,
    },
    rows: [],
    total: 0,
  },
};

function accionsReducer(state = initialState, action) {
  const { payload = {}, type } = action;
  const {
    error,
    isLoading,
    items,
    lastUpdate,
    table,
  } = payload;

  switch (type) {
    default: return state;

    case SET_TABLE_DATA:
      return {
        ...state,
        table,
      };

    case HAS_ERROR:
      return {
        ...state,
        error,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading,
      };

    case READ_ALL:
      return {
        ...state,
        items,
      };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate,
      };
  }
}

export default accionsReducer;
