import clsx from 'clsx';
import {
  element,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';

const ALIGNS = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%',
  },
  content: {
    color: 'gray',
    fontFamily: 'roboto',
    fontSize: 15,
    fontWeight: 500,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  [ALIGNS.CENTER]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [ALIGNS.LEFT]: {
    paddingRight: theme.spacing(2),
  },
  [ALIGNS.RIGHT]: {
    paddingLeft: theme.spacing(2),
  },
}));

function Divider({ align, children }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {align !== ALIGNS.LEFT && <div className={classes.border} />}
      {children && (
        <span className={clsx(classes.content, classes[align])}>
          {children}
        </span>
      )}
      {align !== ALIGNS.RIGHT && <div className={classes.border} />}
    </div>
  );
};

Divider.defaultProps = {
  align: ALIGNS.LEFT,
};

Divider.propTypes = {
  align: oneOf(Object.values(ALIGNS)),
  children: oneOfType([
    element,
    string,
  ]),
};

export default Divider;
