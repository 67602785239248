
import * as Yup from 'yup';

import MESSAGES from 'validations/constants/messages';

const emailFieldSchema = Yup
  .string()
  .email('Escriba un correo electrónico válido')
  .trim()
  .max(255)
  .required('El correo electrónico es requerido');
const passwordFieldSchema = Yup
  .string()
  .max(255)
  .min(8);

export const changePasswordSchema = Yup
  .object({})
  .shape({
    oldPassword: Yup.string()
      .required(MESSAGES.REQUIRED),
    newPassword: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(8, MESSAGES.MIN_LENGTH)
      .matches(/^(?=.*[a-z])/, 'Debe contener al menos una minúscula')
      .matches(/^(?=.*[A-Z])/, 'Debe contener al menos una mayúscula')
      .matches(/^(?=.*[0-9])/, 'Debe contener al menos un número')
      .matches(/^(?=.*[!@#$%^&*+-])/, 'Debe contener al menos un caracter especial'),
  });

export const recoveryFormSchema = Yup
  .object()
  .shape({
    email: emailFieldSchema,
    code: Yup
      .number(),
    password: passwordFieldSchema,
  });

export const signInFormSchema = Yup
  .object()
  .shape({
    email: emailFieldSchema,
    password: passwordFieldSchema
      .required('La contraseña es requerida'),
  });


export const signInWithMfaFormSchema = Yup
  .object()
  .shape({
    email: emailFieldSchema,
    password: passwordFieldSchema
      .required('La contraseña es requerida'),
    code: Yup.string().length(6),
  });

export const requireNewPasswordFormSchema = Yup.object().shape({
  newPassword: passwordFieldSchema
    .required('La contraseña es requerida'),
  verifyNewPassword: passwordFieldSchema
    .required('La contraseña es requerida')
    .oneOf([Yup.ref('newPassword')], 'Debe coincidir'),
});

export const signUpFormSchema = Yup.object().shape({
  name: Yup
    .string()
    .trim(),
  family_name: Yup
    .string()
    .trim(),
  email: emailFieldSchema
    .lowercase(),
  password: Yup.string()
    .required(MESSAGES.REQUIRED)
    .min(8, MESSAGES.MIN_LENGTH)
    .matches(/^(?=.*[a-z])/, 'Debe contener al menos una minúscula')
    .matches(/^(?=.*[A-Z])/, 'Debe contener al menos una mayúscula')
    .matches(/^(?=.*[0-9])/, 'Debe contener al menos un número')
    .matches(/^(?=.*[!@#$%^&*+-])/, 'Debe contener al menos un caracter especial'),
  code: Yup
    .string(),
});

/**
 * @deprecated
 */
export const registerFormSchema = Yup
  .object()
  .shape({
    email: Yup
      .string()
      .email('Escriba un correo electrónico válido')
      .lowercase()
      .max(255)
      .required('El correo electrónico es requerido'),
    password: Yup
      .string()
      .max(255)
      .min(8)
      .required('La contraseña es requerida'),
  });

/**
* @deprecated
*/
export const signupVerifySchema = Yup
  .object()
  .shape({
    email: Yup
      .string()
      .email('Escriba un correo electrónico válido')
      .lowercase()
      .max(255)
      .required('El correo electrónico es requerido'),
    code: Yup
      .string()
      .max(255)
      .required('El código es requerido'),
  });
