import { Link as RouterLink } from 'react-router-dom';
import {
  bool,
  func,
  string,
} from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@material-ui/icons';

import Logo from 'components/Logo';
import { THEMES } from 'constants/index';

import Account from './Account';
import Language from './Language';
import Network from './Network';
import Notifications from './Notifications';
import Ticket from './Ticket';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {},
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar({ className, isOnline, onMobileNavOpen, openMenu, setNavOpen, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon
              children={<MenuIcon />}
              fontSize="small"
            />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={setNavOpen}
          >
            <SvgIcon
              children={openMenu ? <MenuOpenIcon /> : <MenuIcon />}
              fontSize="small"
            />
          </IconButton>
          <RouterLink to="/">
            <Logo
              style={{
                maxHeight: 48,
              }}
              variant={localStorage.getItem('theme') === 'ONE_DARK'
                ? 'imagotipo-horizontal'
                : 'imagotipo-horizontal-dark'
              }
            />
          </RouterLink>
        </Hidden>

        <Box ml={2} flexGrow={1} />

        {!isOnline && (<Network />)}
        <Ticket />
        <Language />
        <Notifications />

        <Box children={<Account />} ml={2} />

      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: string,
  isOnline: bool,
  onMobileNavOpen: func,
  openMenu: bool,
  setNavOpen: func,
};

export default TopBar;
