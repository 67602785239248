import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Link as RouterLink,
  useLocation,
  matchPath,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
} from '@material-ui/core';
import {
  AccountBalance as AccountBalanceIcon,
  Add as AddIcon,
  AllInbox as AllInboxIcon,
  Book as BookIcon,
  CreditCard as CreditCardIcon,
  Dashboard as DashboardIcon,
  Flare as FlareIcon,
  Person as PersonIcon,
  PhonelinkLock as PhonelinkLockIcon,
  Receipt as ReceiptIcon,
  TableChart as TableChartIcon,
  Toll as TollIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  MonetizationOn as MonetizationOnIcon,
  PeopleOutline as PeopleOutlineIcon,
  FeaturedPlayList as FeaturedPlayListIcon,
} from '@material-ui/icons';

import Logo from 'components/Logo';
import { dynamicSort } from 'utils/arrays';
import { parseIfValid } from 'utils/json';
import { hasPermissions } from 'utils/permissions';
import { firstLetter } from 'utils/strings';

import NavSettingsItems from './items/settings';
import NavComplianceItems from './items/compliance';
import NavItem from './NavItem';
import useStyles from './styles';

const company = parseIfValid(localStorage.getItem('company'), false);

function getOptions(items) {
  const showByCompany = {
    '/banks/inquiries/account_balance': [3018],
    '/banks/inquiries/requests': [3018],
    '/banks/normativity/accounts': [3018],
    '/banks/normativity/accounts/add': [3018],
    '/banks/normativity/accountstypes/add': [3018],
    '/banks/normativity/providers': [3018],
    '/banks/normativity/providers/add': [3018],
    '/banks/normativity/authorization/levels': [3018],
    '/banks/normativity/authorization/levels/add': [3018],
    '/banks/normativity/taxes': [3018],
    '/banks/normativity/taxes/add': [3018],
    '/banks/operations': [3018],
    '/banks/operations/transfers': [3018],
    '/banks/operations/requests/authorize': [3018],
    '/banks/operations/cancelations': [3018],
    '/banks/operations/requests/add': [3018],
    '/banks/operations/bulk_load': [3018],
    '/banks/operations/conciliation': [3018],
    '/banks/operations/deposits': [3018],
    '/banks/operations/withdrawals': [3018],
    '/banks/operations/reversal': [3018],
    '/card_payments': [3018],
    '/cards': [3018, 3250],
    '/settings/calendar': [3018, 3139, 3152],
  };
  let options = items
    .filter((item) => (item.href in showByCompany)
      ? showByCompany[item.href].includes(company?.id)
      : true);

  options = options
    .filter((item) => {
      if ([null, undefined, ''].includes(item.module)) {
        return true;
      }
      const permissionName = Array.isArray(item.module)
        ? item.module.map((itemModule) =>

          `${itemModule}.select.list${firstLetter(itemModule)}`,
        )
        : `${item.module}.select.list${firstLetter(item.module)}`;

      return hasPermissions(permissionName, true);
    });

  return options;
}

function renderNavItems({ items, ...rest }, t = (s) => s) {
  const options = getOptions(items);

  return (
    <List disablePadding>
      {options.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          ...rest,
        }, t),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }, t = (s) => s) {
  const key = item.title + depth;
  let title = t(`system.menu.navigation.items.${item.title}`);

  if (title === `system.menu.navigation.items.${item.title}`) {
    title = item.title ?? '';
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items.filter((_) => !_.hidden),
        }, t)}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, openMenu, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { peopleTypes: { items: peopleTypes = [] } } = useSelector((state) => state);
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const navClientsItems = peopleTypes
    .filter((item) =>
      item.group_type === 0
      && !item?.config?.options?.hidden?.active,
    )
    .sort(dynamicSort('name'))
    .map(({ id, name }) => ({
      title: name,
      href: `/people?type=${id}`,
    }));
  const navInvestorsItems = peopleTypes
    .filter((item) => item.group_type === 1)
    .sort(dynamicSort('name'))
    .map(({ id, name }) => ({
      title: name,
      href: `/people?type=${id}`,
    }));
  const navLendersItems = peopleTypes
    .filter((item) => item.group_type === 2)
    .sort(dynamicSort('name'))
    .map(({ id, name }) => ({
      title: name,
      href: `/people?type=${id}`,
    }));

  if (navClientsItems.length === 0 && hasPermissions('clientTypes.insert.addClientTypes')) {
    navClientsItems.push({
      title: 'Agregar tipo',
      href: '/settings/templates/people/add',
      icon: AddIcon,
    });
  }

  const navPeopleItems = [
    {
      href: '/people',
      icon: PersonIcon,
      items: navClientsItems,
      module: 'clients',
      title: 'clients',
    },
  ];

  if (navInvestorsItems.length > 0) {
    navPeopleItems.push({
      href: '/people',
      icon: TransferWithinAStationIcon,
      items: navInvestorsItems,
      title: 'Inversores',
    });
  }

  if (navLendersItems.length > 0) {
    navPeopleItems.push({
      href: '/people',
      icon: TransferWithinAStationIcon,
      items: navLendersItems,
      title: 'Prestamistas',
    });
  }

  const navPortfolioItems = [
    {
      href: '/assets',
      icon: AllInboxIcon,
      module: 'assets',
      title: 'assets',
    },
    {
      href: '/banks',
      icon: AccountBalanceIcon,
      items: [
        {
          title: 'Consultas',
          items: [
            {
              href: '/banks/inquiries/account_status',
              title: 'Estado de cuenta',
            },
            {
              href: '/banks/inquiries/account_balance',
              title: 'Saldo en cuentas',
            },
            {
              href: '/banks/inquiries/requests',
              title: 'Solicitudes',
            },
          ],
        },
        {
          href: '/banks/normativity',
          title: 'Normatividad',
          items: [
            {
              href: '/banks/normativity/accounts',
              title: 'Cuentas bancarias',
            },
            {
              href: '/banks/normativity/providers',
              title: 'Proveedores',
            },
            {
              href: '/banks/normativity/authorization/levels',
              title: 'Niveles de autorización por banco',
            },
            {
              href: '/banks/normativity/taxes',
              title: 'Retenciones de impuestos',
            },
            {
              href: '/banks/normativity/accountstypes',
              title: 'Tipos de cuentas bancarias',
            },
          ],
        },
        {
          href: '/banks/operations',
          title: 'Operaciones',
          items: [
            {
              href: '/banks/operations',
              title: 'Listado de operaciones',
            },
            {
              href: '/banks/operations/transfers',
              title: 'Agregar traspaso entre cuentas',
            },
            {
              href: '/banks/operations/requests/authorize',
              title: 'Autorización de solicitudes',
            },
            {
              href: '/banks/operations/cancelations',
              title: 'Cancelación de movimientos',
            },
            {
              href: '/banks/operations/requests/add',
              title: 'Captura de solicitud',
            },
            {
              href: '/banks/operations/bulk_load',
              title: 'Carga de movimientos',
            },
            {
              href: '/banks/operations/conciliation',
              title: 'Conciliación de movimientos',
            },
            {
              href: '/banks/operations/deposits',
              title: 'Depósitos',
            },
            {
              href: '/banks/operations/withdrawals',
              title: 'Retiros',
            },
            {
              href: '/banks/operations/reversal',
              title: 'Reversión de movimientos',
            },
          ],
        },
      ],
      module: 'banks',
      title: 'banks',
    },
    ...navPeopleItems,
    {
      href: '/devices',
      icon: PhonelinkLockIcon,
      module: 'devices',
      title: 'devices',
    },
    {
      href: '/payments',
      icon: TollIcon,
      module: 'payments',
      title: 'payments',
    },
    {
      href: '/payment_orders',
      icon: ReceiptIcon,
      module: 'paymentOrders',
      title: 'payments_order',
    },
    {
      href: '/deposits',
      icon: ReceiptIcon,
      module: 'deposits',
      title: 'deposits',
    },
    {
      href: '/billing',
      icon: ReceiptIcon,
      module: 'billing',
      title: 'billing',
    },
    {
      href: '/cards',
      icon: CreditCardIcon,
      //module: 'cards',
      title: 'cards',
    },
    // {
    //   href: '/card_payments',
    //   icon: ViewModuleIcon,
    //   items: [
    //     {
    //       title: 'Movimientos',
    //       items: [
    //         {
    //           href: '/movements/transactions',
    //           title: 'Transacciones',
    //         },
    //       ],
    //     },
    //     {
    //       href: '/commerce',
    //       icon: CreditCardIcon,
    //       module: 'commerce',
    //       title: 'Comercios',
    //     },
    //     {
    //       href: '/developers',
    //       icon: CodeIcon,
    //       title: 'Desarrolladores',
    //     },
    //     {
    //       href: '/payment_links',
    //       icon: LinkIcon,
    //       title: 'Links de pago',
    //     },
    //   ],
    //   title: 'Pagos con tarjeta',
    // },
    {
      icon: MonetizationOnIcon,
      module: 'debtCollections',
      title: 'debt_collections',
      items: [
        {
          href: '/debt_collections/orders',
          icon: FeaturedPlayListIcon,
          title: 'orders',
        },
        {
          href: '/debt_collections/managers',
          icon: PeopleOutlineIcon,
          title: 'Gestores',
        },
      ],
    },
  ];

  const navConfig = [
    {
      items: [
        {
          href: '/',
          icon: DashboardIcon,
          module: 'dashboard',
          title: 'dashboard',
        },
        {
          href: '/reports',
          icon: TableChartIcon,
          module: 'reports',
          title: 'reports',
        },
      ],
      permissions: [],
      subheader: '',
    },
    {
      items: [].includes(company?.id)
        ? [{
          href: '/people/accounts',
          icon: DashboardIcon,
          module: 'clients',
          title: 'Cuentas por cliente',
        }]
        : navPortfolioItems,
      subheader: 'portfolio',
    },
    {
      items: NavSettingsItems,
      subheader: 'settings',
    },
    {
      items: NavComplianceItems,
      subheader: 'compliance',
    },
    {
      items: [
        {
          href: '/logs/binnacle',
          icon: BookIcon,
          module: 'logs',
          title: 'binnacle',
        },
      ],
      subheader: 'logs',
    },
  ];

  if (process.env.NODE_ENV === 'development') {
    navConfig.push({
      subheader: 'demo',
      items: [
        {
          title: 'DynamicForm',
          icon: FlareIcon,
          href: '/_demo/forms',
        },
        {
          title: 'Identity',
          icon: FlareIcon,
          href: '/_demo/identity',
        },
        {
          title: 'Payment methods',
          icon: FlareIcon,
          href: '/_demo/payment_methods',
        },
        {
          title: 'Table',
          icon: FlareIcon,
          href: '/_demo/table',
        },
      ],
    });
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{
        suppressScrollX: true,
      }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo
                style={{
                  maxHeight: 48,
                }}
                variant="imagotipo-horizontal"
              />
            </RouterLink>
          </Box>
        </Hidden>

        <Divider />

        <Box p={2}>
          {navConfig.map((config, index) => (<>
            {(getOptions(config.items).length ?? 0) > 0 && (<List
              children={renderNavItems({
                items: config.items,
                pathname: location.pathname,
              }, t)}
              key={index}
              subheader={
                <ListSubheader
                  children={config.subheader
                    ? t(`system.menu.navigation.groups.${config.subheader}`)
                    : ''}
                  disableGutters
                  disableSticky
                />
              }
            />)}
          </>))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        />
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes[openMenu
              ? 'desktopDrawer'
              : 'desktopMiniDrawer'],
          }}
          open={openMenu}
          variant="persistent"
        />
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openMenu: PropTypes.bool,
};

export default NavBar;
