import { useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  makeStyles,
} from '@material-ui/core';

import { THEMES } from 'constants/index';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {},
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar() {
  const { data: { company } } = useSelector(({ publicForms }) => publicForms);
  const classes = useStyles();

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        {company?.logo?.imagotipoDarkH && <a href={company.url} style={{
          cursor: 'pointer',
        }}>
          <img
            style={{
              maxHeight: 48,
            }}
            alt="Company logo"
            src={company.logo.imagotipoDarkH}
          /></a>}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {};

export default TopBar;
