import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  mobileDrawer: {
    width: 220,
  },
  desktopDrawer: {
    height: 'calc(100% - 64px)',
    top: 64,
    width: 220,
  },
  desktopMiniDrawer: {
    height: 'calc(100% - 64px)',
    top: 50,
    width: 60,
  },
  avatar: {
    cursor: 'pointer',
    height: 64,
    width: 64,
  },
}));
