import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

const conditionalSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    isRange: Yup
      .boolean()
      .default(false),
    index: Yup
      .number()
      .integer()
      .positive(),
    field: Yup
      .number()
      .integer()
      .positive()
      .required(),
    comparator: Yup
      .number()
      .integer()
      .positive()
      .required(),
    value1: Yup
      .mixed()
      .required(),
    value2: Yup
      .mixed()
      .when('isRange', {
        is: true,
        then: Yup.mixed().required(),
      }),
    weight: Yup
      .number()
      .default(0),
    deleted: Yup
      .boolean()
      .default(false),
  });

const linkSchema = Yup
  .object()
  .shape({
    link: Yup
      .number(),
    config: Yup.mixed(),
  });

export const campaignsFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    description: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    type: Yup
      .number()
      .integer()
      .positive()
      .default(2)
      .typeError('Selecciona una opción')
      .required(MESSAGE.REQUIRED),
    product: Yup
      .number()
      .integer()
      .positive()
      .typeError('Selecciona una opción')
      .required(MESSAGE.REQUIRED),
    enabled: Yup
      .number()
      .oneOf([0, 1])
      .default(1),
    node: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    trigger: linkSchema,
    actions: Yup
      .array()
      .of(linkSchema),
    conditionals: Yup
      .array()
      .of(conditionalSchema),
  });

export const stagesFormSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    description: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
  });
