import {
  HAS_ERROR,
  IS_LOADING,
  INITIAL_CHARGE,
  GET_COMPANY,
  UPDATE_WEBSOCKET,
} from 'redux/constants/system';

const initialState = {
  error: null,
  isLoading: false,
  company: {},
  navbar: [],
  theme: {},
  socketMessage: {},
};

function systemRedurer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    default: return state;

    case HAS_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case INITIAL_CHARGE:
      return {
        ...state,
        payload,
      };

    case GET_COMPANY:
      return {
        ...state,
        company: payload.company,
      };

    case UPDATE_WEBSOCKET:
      return {
        ...state,
        socketMessage: payload.socketMessage,
      };
  }
}

export default systemRedurer;
