import { useTranslation } from 'react-i18next';

function useCatalogTranslation() {
  const { i18n } = useTranslation('translation');

  const catalogTranslate = function (str, group, name) {
    const key = String(str).toLowerCase();
    const translate = i18n.getFixedT(null, null, `catalogs.${group}`);

    return i18n.exists(`catalogs.${group}.${name}.${key}`)
      ? translate(`${name}.${key}`)
      : str;
  };

  return catalogTranslate;
}

export default useCatalogTranslation;
