import {
  number,
  object,
  string,
} from 'yup';

import MESSAGE from 'validations/constants/messages';

export const groupsFormSchema = object()
  .shape({
    details: object()
      .shape({
        address: object().shape({
          postal_code: string()
            .matches(/^[0-9]+$/, MESSAGE.NUMBER)
            .length(5, MESSAGE.LENGTH),
        }),
        contact: object().shape({
          email: string()
            .email(MESSAGE.EMAIL),
        }),
      })
      .default({}),
    id: number()
      .integer()
      .positive(),
    name: string()
      .min(3, MESSAGE.MIN_LENGTH)
      .trim()
      .required(MESSAGE.REQUIRED),
    parent: number()
      .integer(),
  });
