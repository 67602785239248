import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons';

function KeyValueControl(props) {
  const {
    events = {},
    label,
    name: controlName,
    value,
    onChange,
    settings = {},
  } = props;
  const [items, setItems] = useState([
    ...Object.entries(value || []),
    ['', ''],
  ]);

  const handleAdd = () => {
    const tmpItems = items;
    const index = tmpItems.length - 1;
    const [key, val] = tmpItems[index];

    if (key && val) {
      setItems([
        ...tmpItems,
        ['', ''],
      ]);
    }
  };

  const handleDelete = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleBlur = ({ target: { name, value } }) => {
    // onBlur();
  };

  const handleChange = ({ target: { name, value } }) => {
    const tmpItems = items;
    const [elem, index] = name.split('_');
    tmpItems[index][elem === 'key' ? 0 : 1] = value;

    setItems(tmpItems);

    const target = {
      name: controlName,
      value: Object.fromEntries(tmpItems),
    };
    onChange({
      target,
    });

    if (events.onChange) {
      events.onChange(target);
    }
  };

  return (<fieldset style={{
    borderRadius: 5,
  }}>
    <legend style={{
      marginLeft: 8,
    }}>
      <Typography color="textSecondary" variant="caption">
        <Box style={{
          marginLeft: 5,
          marginRight: 5,
        }}>
          {label}
        </Box>
      </Typography>
    </legend>
    <Box m={1}>
      {items.map(([key, val], index) => (
        <Grid container key={index} spacing={3}>
          <Grid item md={5}>
            <TextField
              name={`key_${index}`}
              value={key}
              fullWidth={true}
              label={settings.keyLabel || 'Llave'}
              size="small"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              name={`value_${index}`}
              value={val}
              fullWidth={true}
              label={settings.valueLabel || 'Valor'}
              size="small"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={1}>
            <IconButton
              children={(items.length === index + 1) ? <AddIcon /> : <RemoveIcon />}
              color={(items.length === index + 1) ? 'primary' : 'error'}
              size="small"
              onClick={() => {
                if (items.length === index + 1) {
                  handleAdd();
                } else {
                  handleDelete(index);
                }
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  </fieldset>);
}

KeyValueControl.defaultProps = {
  value: {},
  onChange: () => { },
  onBlur: () => { },
};

KeyValueControl.propTypes = {
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  settings: PropTypes.shape({
    keyLabel: PropTypes.string,
    valueLabel: PropTypes.string,
  }),
};

export default KeyValueControl;
