import * as Yup from 'yup';

import { getStaticData, STATIC_DATA } from 'services/dynamicore';
import MESSAGES from 'validations/constants/messages';

function isValidClabe(msg) {
  return Yup.mixed().test({
    name: 'isValidClabe',
    exclusive: false,
    message: msg || 'invalid',
    test(value) {
      if (!value || value.length < 3) {
        return false;
      }

      const [bank] = getStaticData(STATIC_DATA.BANKS, {
        clabePrefix: value.substring(0, 3),
      });

      return !!bank;
    },
  });
}

Yup.addMethod(Yup.string, 'clabe', isValidClabe);

export const addClabeSchema = Yup
  .object()
  .shape({
    nif: Yup
      .string()
      .trim()
      .uppercase()
      .max(13, MESSAGES.MAX_LENGTH)
      .min(12, MESSAGES.MIN_LENGTH)
      .required(MESSAGES.REQUIRED),
    name: Yup
      .string()
      .required(MESSAGES.REQUIRED),
    email: Yup
      .string()
      .email(MESSAGES.EMAIL)
      .trim()
      .lowercase()
      .required(MESSAGES.REQUIRED),
    phone: Yup
      .string()
      .matches(/^[0-9]+$/)
      .length(10, MESSAGES.dDDDD)
      .required(MESSAGES.REQUIRED),
    account: Yup
      .string()
      .clabe(MESSAGES.INVALID)
      .required(MESSAGES.REQUIRED),
  });
