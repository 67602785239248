import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Assessment as AssessmentIcon,
  Business as BusinessIcon,
  Cached as CachedIcon,
  ExitToApp as ExitToAppIcon,
  VpnLock as VpnLockIcon,
} from '@material-ui/icons';

import Dynamicore, { SERVICES } from 'services/dynamicore';
import { hasPermissions } from 'utils/permissions';
import { parseIfValid } from 'utils/json';

import AccountModal from './AccountModal';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    marginRight: theme.spacing(1),
    width: 32,
  },
  avatarArrow: {
    paddingLeft: '0.15em',
  },
  popover: {
    width: 220,
  },
}));

function AccountMenuControl() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [modal, setModal] = useState();
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async function () {
    try {
      await Auth.signOut({
        global: true,
      });
    } catch (error) {
      console.debug(error.message);
    } finally {
      localStorage.clear();

      window.location.replace('/auth/login');
    }
  };

  const handleModalAccount = async function (recommendMFA = false) {
    const user = await Auth.currentAuthenticatedUser();

    setModal({
      data: {
        frmSetPreferredMFA: {
          mfa: {
            SMS_MFA: 'SMS',
            SOFTWARE_TOKEN_MFA: 'TOTP',
          }[user?.preferredMFA] || 'NOMFA',
          phone_number: user?.attributes?.phone_number,
        },
        frmUserToken: user,
      },
      events: {
        onClose: setModal,
      },
      recommendMFA,
      open: true,
    });
  };

  const handleReload = async function () {
    try {
      const [company] = (await Dynamicore(SERVICES.COMPANY).get())?.values ?? [];
      const { values = [] } = await Dynamicore(SERVICES.COMPANY_MODULES).get({
        company: company?.id,
      });
      const activeModules = {};

      values
        .filter((item) => item.enabled)
        .forEach((item) => {
          activeModules[item.module] = item.config;
        });
      localStorage.setItem(
        'modules',
        JSON.stringify(activeModules),
      );

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInit = async function () {
    const company = parseIfValid(localStorage.getItem('company'), false) || {};
    const user = await Auth.currentAuthenticatedUser();

    if (company?.config?.security?.recommendMFA && user.preferredMFA === 'NOMFA') {
      company.config.security.recommendMFA = false;
      localStorage.setItem('company', JSON.stringify(company));

      setTimeout(function () {
        handleModalAccount(true);
      }, process.env.NODE_ENV === 'production' ? 5000 : 0);
    }

    setCurrentUser({
      label: [
        user?.attributes?.name || '',
        user?.attributes?.family_name || '',
      ]
        .filter((item) => !!item)
        .join(' ') || user?.username || 'Usuario',
    });
  };

  const menuOptions = [
    {
      fn: function () {
        history.push('/settings/company');
      },
      hidden: !hasPermissions('company.select.getCompany'),
      icon: <BusinessIcon fontSize="small" />,
      label: t('system.menu.user.company'),
    },
    {
      fn: handleModalAccount,
      icon: <VpnLockIcon fontSize="small" />,
      label: t('system.menu.user.account'),
    },
    {
      fn: function () {
        history.push('/pld/reports/internal/add');
      },
      hidden: !hasPermissions('pld.select'),
      icon: <AssessmentIcon fontSize="small" />,
      label: t('system.menu.user.internal_report'),
    },
    {
      type: 'divider',
    },
    {
      icon: <CachedIcon fontSize="small" />,
      label: t('system.menu.user.reload'),
      fn: handleReload,
    },
    {
      fn: handleLogout,
      icon: <ExitToAppIcon fontSize="small" />,
      label: t('system.menu.user.log_out'),
    },
  ];

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <>
      <AccountModal {...modal} />

      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="" className={classes.avatar} />
        {currentUser && (<Hidden smDown>
          <Typography
            children={currentUser?.label}
            color="inherit"
            variant="h6"
          />
          {isOpen
            ? <ArrowDropUpIcon className={classes.avatarArrow} />
            : <ArrowDropDownIcon className={classes.avatarArrow} />
          }
        </Hidden>)}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{
          className: classes.popover,
        }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {menuOptions.filter((item) => item.hidden !== true).map((item, index) => {

          if (item.type === 'divider') {
            return (<Divider key={index} />);
          }

          return (<MenuItem
            children={<>
              <ListItemIcon children={item.icon} /> {item.label}
            </>}
            key={index}
            onClick={() => {
              handleClose();

              if (typeof item.fn === 'function') {
                item.fn();
              }
            }}
          />);
        })}
      </Menu>
    </>
  );
}

export default AccountMenuControl;
