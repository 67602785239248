import { useEffect } from 'react';
import PropTypes from 'prop-types';

function useScript(url, async = true) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = async;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

useScript.PropTypes = {
  url: PropTypes.string.isRequired,
};

export default useScript;
