import { Fragment } from 'react';
import {
  node,
  number,
  oneOf,
  string,
} from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

function LoaderCardContainer({ children }) {
  return (<Box mt={2}>
    <Card>
      <CardContent
        children={children}
      />
    </Card>
  </Box>);
}

LoaderCardContainer.propTypes = {
  children: node,
};

function Loader({ align, color, message, mt, size, variant }) {
  const Component = {
    card: LoaderCardContainer,
    box: Box,
  }[variant] ?? Fragment;

  return (<Component mt={mt}>
    <Grid container justifyContent={align}>
      <CircularProgress
        color={color}
        size={size}
      />
      {message && <Typography
        children={message}
        style={{
          marginLeft: 10,
        }}
        variant="body1"
      />}
    </Grid>
  </Component>);
}

Loader.defaultProps = {
  align: 'center',
  color: 'secondary',
  size: 30,
};

Loader.propTypes = {
  align: oneOf([
    'center',
    'left',
  ]),
  color: oneOf([
    'primary',
    'secondary',
  ]),
  message: string,
  mt: number,
  size: number,
  variant: oneOf([
    'box',
    'card',
  ]),
};

export default Loader;
