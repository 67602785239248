import { useEffect } from 'react';
import { string } from 'prop-types';

import useStyles from './styles';

function Video({ id }) {
  const classes = useStyles();

  useEffect(() => {
    const video = document.querySelector(`#${id}_video_stream`);
    const constraints = {
      audio: false,
      video: {
        facingMode: {
          ideal: 'environment',
        },
        height: 1080,
        width: 1920,
      },
    };

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
          video.srcObject = stream;
        })
        .catch(function (error) {
          console.error('Something went wrong!');
        });
    }

    return () => {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        track.stop();
      }

      video.srcObject = null;
    };

  }, []);

  return (<>
    <video
      alt="Webcam video stream"
      autoPlay
      className={classes.video}
      id={`${id}_video_stream`}
      playsInline
    />
  </>);
}

Video.propTypes = {
  id: string.isRequired,
};

export default Video;
