import * as Yup from 'yup';

import MESSAGE from '../../constants/messages';

export const peopleSatKeysSchema = Yup
  .object()
  .shape({
    rfc: Yup
      .string()
      .uppercase()
      .trim()
      .max(13, MESSAGE.MAX_LENGTH)
      .min(12, MESSAGE.MIN_LENGTH)
      .required(MESSAGE.REQUIRED),
    password: Yup
      .string()
      .required(MESSAGE.REQUIRED),
  });
