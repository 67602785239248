import { hasPermissions } from 'utils/permissions';

const items = [];

if (hasPermissions('pld.select')) {
  items.push({
    title: 'catalogs',
    items: [
      {
        href: '/pld/catalogs/actions',
        title: 'actions',
      },
      {
        href: '/pld/catalogs/monetary_instruments',
        title: 'Instrumentos monetarios',
      },
      {
        href: '/pld/catalogs/country_jurisdiction',
        title: 'country_jurisdiction',
      },
      {
        href: '/pld/catalogs/operation_master',
        title: 'Maestro de operaciones',
      },
      {
        href: '/pld/catalogs/parameters',
        title: 'Parámetros generales',
      },
      {
        href: '/pld/catalogs/reporting_reasons',
        title: 'Razones de reporte',
      },
      {
        href: '/pld/catalogs/alert_types',
        title: 'Tipos de alertas',
      },
      {
        href: '/pld/catalogs/operation_types',
        title: 'Tipo de operación',
      },
      {
        href: '/pld/catalogs/black_list',
        title: 'Listas negras',
      },
    ],
  });
}

if (hasPermissions('pld.select')) {
  items.push({
    title: 'Alertamiento',
    items: [
      {
        href: '/pld/alerts',
        title: 'alerts',
      },
      {
        href: '/pld/customer_accounts',
        title: 'customer_accounts',
      },
    ],
  });
}

if (hasPermissions('pld.select')) {
  items.push({
    title: 'Análisis',
    items: [
      {
        href: '/pld/analysis/black_list',
        title: 'Búsquedas individuales',
      },
      {
        href: '/pld/analysis/risk_matrix',
        title: 'Matriz de riesgo',
      },
      {
        href: '/pld/analysis/client_movements',
        title: 'Movimientos por cliente',
      },
    ],
  });
}

if (hasPermissions('pld.select')) {
  items.push({
    items: [
      {
        href: '/pld/reports/calendar',
        title: 'calendar',
      },
      {
        href: '/pld/reports/investigation_cases',
        title: 'investigation_cases',
      },
      {
        href: '/pld/reports/internal',
        title: 'internal',
      },
    ],
    title: 'reports',
  });
}

export default items;
