import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const rolFormSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    description: Yup
      .string()
      .required(MESSAGE.REQUIRED),
  });
