export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGOUT = 'LOGOUT';
export const CODE_FETCHING = 'CODE_FETCHING';
export const CODE_SENDED = 'CODE_SENDED';
export const CODE_FAILURE = 'CODE_FAILURE';
export const RECOVERY_SUCCESS = 'RECOVERY_SUCCESS';
export const SIGNUP_FETCHING = 'SIGNUP_FETCHING';
export const SIGNUP_CODE_SENDED = 'SIGNUP_CODE_SENDED';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
