import { useState } from 'react';
import {
  arrayOf,
  bool,
  element,
  func,
  oneOf,
  shape,
  string,
} from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import Paper from './Paper';
import useStyles from './styles';

function Modal(props) {
  const {
    actions,
    canFullScreen,
    children,
    events,
    fullWidth,
    maxWidth,
    open,
    title,
    centered,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [forceFull, setForceFull] = useState(false);

  const { onClose } = events;

  return (
    <Dialog
      id={'principalModal'}
      aria-labelledby="dialog-title"
      fullScreen={fullScreen || forceFull}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={(event, reason) => {
        if (!['backdropClick'].includes(reason)) {
          onClose(event);
        }
      }}
      open={open}
      PaperComponent={props.draggable ? Paper : undefined}
      className={centered ? classes.borderModal : undefined}
    >
      {title && <DialogTitle
        children={title}
        id="dialog-title"
        className={centered ? classes.centeredTitle : undefined}
        style={{
          cursor: props.draggable ? 'move' : undefined,
        }}
      />}

      {(canFullScreen && !fullScreen) && (<IconButton
        aria-label="fullscreen"
        className={onClose ? classes.secondButton : classes.firstButton}
        onClick={() => setForceFull(!forceFull)}
      >
        <FullscreenIcon />
      </IconButton>)}

      {onClose && <IconButton
        aria-label="close"
        children={<CloseIcon />}
        className={classes.firstButton}
        onClick={onClose}
      />}

      {children && (<DialogContent
        children={children}
        dividers
      />)}

      {!!actions.length && <>
        <DialogActions>
          {actions.map((item, index) => (
            <Button
              children={item.label}
              color={item.processing ? 'secondary' : item.color}
              disabled={item.processing || item.disabled}
              form={item.form}
              key={index}
              startIcon={item.processing ?
                (<CircularProgress
                  color="secondary"
                  size={18}
                  style={{
                    marginRight: 5,
                  }}
                />)
                : item.icon}
              style={{
                ...(item.hidden ? {
                  display: 'none',
                } : {}),
                ...(item.left ? {
                  marginRight: 'auto',
                } : {}),
                ...(item.color === 'error' && !item.processing ? {
                  color: 'white',
                  backgroundColor: red[500],
                  '&:hover': {
                    backgroundColor: red[900],
                  },
                } : {}),
              }}
              type={item.type}
              variant={item.color ? 'contained' : 'text'}
              onClick={item.fn || item.action}
            />
          ))}
        </DialogActions>
      </>}

    </Dialog>
  );
}

Modal.defaultProps = {
  actions: {},
  events: {},
  fullWidth: true,
  maxWidth: 'sm',
  open: false,
};

Modal.propTypes = {
  actions: arrayOf(shape({
    color: oneOf([
      'error',
      'primary',
      'secondary',
    ]),
    disabled: bool,
    fn: func,
    icon: element,
    label: string,
    processing: bool,
  })),
  canFullScreen: bool,
  children: element,
  draggable: bool,
  events: shape({
    onClose: func,
  }),
  fullWidth: bool,
  maxWidth: oneOf([
    'lg',
    'md',
    'sm',
    'xl',
    'xs',
  ]),
  open: bool,
  title: string,
  centered: bool,
};

export default Modal;
