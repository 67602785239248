import {
  createContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { THEMES } from 'constants/index';
import { storeSettings } from 'utils/settings';

const SettingsContext = createContext();
const theme = THEMES.LIGHT;

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme,
};

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  const value = {
    settings: currentSettings,
    saveSettings: handleSaveSettings,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
