import PropTypes from 'prop-types';
import ColorPicker from 'material-ui-color-picker';

function ColorControl(props) {
  const {
    error,
    events,
    helperText,
    label,
    name,
    onChange,
    size,
    value,
  } = props;

  const handleChange = function (color) {
    const target = {
      name,
      value: color,
    };

    onChange({
      target,
    });

    if (events.onChange) {
      events.onChange(target);
    }
  };

  return (<ColorPicker
    error={error}
    fullWidth
    helperText={helperText}
    label={label}
    name={name}
    size={size}
    value={value || ''}
    InputLabelProps={{
      shrink: !!value,
    }}
    inputProps={{
      value,
    }}
    variant="outlined"
    onChange={handleChange}
  />);
}

ColorControl.defaultProps = {
  disabled: false,
  events: {},
  size: 'small',
  onChange: () => { },
  onBlur: () => { },
};

ColorControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default ColorControl;
