import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CustomSelect from '../components/CustomSelect';
import { DEFAULT_LOCALE_EN } from '../locale';
import { classNames } from '../utils';
import { UNITS } from '../constants';

function Hours(props) {
  const {
    value,
    setValue,
    locale,
    className,
    disabled,
    readOnly,
    leadingZero,
    clockFormat,
    period,
  } = props;

  const internalClassName = useMemo(() => classNames({
    'react-js-cron-field': true,
    'react-js-cron-hours': true,
    [`${className}-field`]: !!className,
    [`${className}-hours`]: !!className,
  }), [className]);

  return React.createElement('div', {
    className: internalClassName,
  }, locale.prefixHours !== '' && React.createElement(
    'span',
    null,
    locale.prefixHours || DEFAULT_LOCALE_EN.prefixHours.replace,
  ), React.createElement(CustomSelect, {
    placeholder: locale.emptyHours || DEFAULT_LOCALE_EN.emptyHours,
    value: value,
    unit: UNITS[1],
    setValue: setValue,
    locale: locale,
    className: className,
    disabled: disabled,
    readOnly: readOnly,
    leadingZero: leadingZero,
    clockFormat: clockFormat,
    period: period,
  }));
}

Hours.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  locale: PropTypes.shape({
    emptyHours: PropTypes.string,
    prefixHours: PropTypes.string,
  }),
  className: PropTypes.any,
  disabled: PropTypes.any,
  readOnly: PropTypes.any,
  leadingZero: PropTypes.any,
  clockFormat: PropTypes.any,
  period: PropTypes.any,
};

export default Hours;
