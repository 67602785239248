import { combineReducers } from 'redux';

import campaignsReducer from './campaigns';
import dashboardReducer from './dashboard';
import flowsReducer from './flows';
import notificationsRedurer from './notifications';
import peopleRedurer from './people';
import peopleSatRedurer from './peopleSat';
import peopleSatStatementsRedurer from './peopleSatStatements';
import productsReducer from './products';
import productTypesReducer from './productTypes';
import publicFormsReducer from './publicForms';
import queriesReducer from './queries';
import reportsRedurer from './reports';
import rolesRedurer from './roles';
import templatesFilesReducer from './templatesFiles';
import templatesPeopleReducer from './templatesPeople';
import systemReducer from './system';
import instrumentsReducer from './instruments';
import operationTypesReducer from './operationTypes';
import generalParamsReducer from './generalParams';
import accionsReducer from './accions';
import reportReasonsReducer from './reportReasons';
import authReducer from './auth';
import peopleTypesReducer from './peopleTypes';
import recoveryReducer from './recovery';
import signupReducer from './signup';

const rootReducer = combineReducers({
  campaigns: campaignsReducer,
  dashboard: dashboardReducer,
  flows: flowsReducer,
  notifications: notificationsRedurer,
  people: peopleRedurer,
  peopleSat: peopleSatRedurer,
  peopleSatStatements: peopleSatStatementsRedurer,
  products: productsReducer,
  productTypes: productTypesReducer,
  publicForms: publicFormsReducer,
  queries: queriesReducer,
  reports: reportsRedurer,
  roles: rolesRedurer,
  templatesFiles: templatesFilesReducer,
  templatesPeople: templatesPeopleReducer,
  system: systemReducer,
  instruments: instrumentsReducer,
  operationTypes: operationTypesReducer,
  generalParams: generalParamsReducer,
  accions: accionsReducer,
  reportReasons: reportReasonsReducer,
  auth: authReducer,
  peopleTypes: peopleTypesReducer,
  recovery: recoveryReducer,
  signup: signupReducer,
});

export default rootReducer;
