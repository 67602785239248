import { lazy } from 'react';

import AuthenticationLayout from 'layouts/AuthenticationLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const authenticationRoutes = {
  layout: AuthenticationLayout,
  path: '/auth',
  routes: [
    {
      exact: true,
      path: '/auth/login',
      component: lazy(() => import('views/Authentication/SignIn')),
    },
    {
      exact: true,
      path: '/auth/recovery',
      component: lazy(() => import('views/Authentication/RecoveryView')),
    },
    {
      exact: true,
      path: '/auth/register',
      component: lazy(() => import('views/Authentication/SignUp')),
    },
    error404,
  ],
};

export default authenticationRoutes;
