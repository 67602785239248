import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { GCP_RECAPTCHA_SITE_KEY } from 'settings/gcp';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  reCaptcha: {
    display: 'inline-block',
  },
}));

function CaptchaControl(props) {
  const classes = useStyles();

  const {
    error,
    events,
    name,
    onChange,
  } = props;

  const handleChange = (value) => {
    const target = {
      name,
      value,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  if (!GCP_RECAPTCHA_SITE_KEY) {
    return (<Alert
      children={'ReCAPTCHA KEY is undefined'}
      severity="error"
    />);
  }

  return (<div className={classes.root}>

    {error && (<Alert
      children={error}
      severity="error"
    />)}

    {GCP_RECAPTCHA_SITE_KEY && <ReCAPTCHA
      className={classes.reCaptcha}
      sitekey={GCP_RECAPTCHA_SITE_KEY}
      onChange={handleChange}
    />}
  </div>);
}

CaptchaControl.propTypes = {
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
  }),
};

export default CaptchaControl;
