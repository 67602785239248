import {
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SIGNUP_FETCHING,
  SIGNUP_CODE_SENDED,
} from 'redux/constants/auth';

const initialState = {
  codeSended: false,
  isFeching: false,
  email: '',
  pass: '',
  error: null,
};

function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_FETCHING:
      return {
        ...state,
        isFeching: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        codeSended: false,
        isFeching: false,
        email: '',
        pass: '',
        error: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isFeching: false,
        error: action.error,
      };
    case SIGNUP_CODE_SENDED:
      return {
        isFetching: false,
        codeSended: true,
        email: action.payload.email,
        pass: action.payload.pass,
        error: null,
      };
    default:
      return state;
  }
};

export default signUpReducer;
