import {
  Box,
  Button,
  ButtonGroup as MuiButtonGroup,
} from '@material-ui/core';

import { DEFAULT_PROPS, PROP_TYPES } from './constants';
import useStyles from './styles';

function ButtonGroup(props) {
  const classes = useStyles();
  const {
    actions,
    onChange,
    size,
    value,
    variant,
  } = props;

  return (<Box textAlign="center" pb={props.pb} pt={props.pt}>
    <MuiButtonGroup className={classes.buttonGroup} size={size}>
      {actions.map((action, index) => (<Button
        children={action.label}
        color={action.value !== undefined && value === action.value
          ? props.color
          : 'default'}
        disableElevation
        endIcon={actions.endIcon}
        key={index}
        onClick={() => {
          onChange(action.value);
        }}
        startIcon={actions.startIcon}
        style={{
          borderRadius: 30,
          width: props.width,
        }}
        variant={variant}
      />))}
    </MuiButtonGroup>
  </Box>);
}

ButtonGroup.defaultProps = DEFAULT_PROPS;
ButtonGroup.propTypes = PROP_TYPES;

export default ButtonGroup;
