import { Link as RouterLink } from 'react-router-dom';
import {
  func,
  node,
  oneOf,
  string,
} from 'prop-types';
import { Link as MuiLink } from '@material-ui/core';

function Link(props) {
  const componentProps = {};

  switch (props.variant) {
    default: {
      componentProps.component = RouterLink;
      componentProps.to = props.to;

      break;
    }

    case 'email': {
      componentProps.href = `mailto: ${props.to}`;

      break;
    }

    case 'external': {
      componentProps.href = props.to;
      componentProps.target = '_blank';

      break;
    }

    case 'function': {
      componentProps.onClick = props.onClick;

      break;
    }

    case 'telephone': {
      componentProps.href = `tel: ${props.to}`;

      break;
    }
  }

  return (<MuiLink
    children={props.children}
    style={{
      cursor: 'pointer',
    }}
    underline="none"
    {...componentProps}
  />);
}

Link.defaultProps = {
  variant: 'router',
};

Link.propTypes = {
  children: node,
  onClick: func,
  to: string,
  variant: oneOf([
    'email',
    'external',
    'function',
    'router',
    'telephone',
  ]),
};

export default Link;
