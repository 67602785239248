import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import imagotipoHorizontal from 'assets/svg/imagotipo-horizontal.svg';
import isotipo from 'assets/svg/isotipo.svg';
import { Page, Link } from 'components';
import { useWindowDimensions } from 'hooks';
import { BUILD_VERSION } from 'settings';

import useStyles from './styles';

function LayoutAuth({ children, title }) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Page maxWidth="lg" title={title} className={classes.bg}>
        <div className={classes.bgFullHeight}>

          <div className={classes.waves} />
          {width > 1024 && height < 780
            ? (<div className={classes.back_footer} />)
            : null
          }

          <div className={classes.container_icons}>
            {function () {
              const elems = [];
              [
                {
                  size: 5,
                  value: '',
                },
                {
                  size: 8,
                  value: 'square_',
                },
              ].forEach(({ size, value }) => {
                for (let i = 1; i <= size; i++) {
                  elems.push(<div
                    className={classes[`icon_${value}${i}`]}
                  />);
                }
              });

              return elems;
            }()}
          </div>

          <Box
            alignItems="center"
            children={<img src={imagotipoHorizontal} alt="DynamiCore" />}
            className={classes.header_imagotipo}
            display="flex"
            mb={8}
          />

          <Box
            alignItems="center"
            children={<img src={isotipo} alt="DynamiCore" />}
            className={classes.header_isotipo}
            display="flex"
            mb={8}
          />

          {children}

        </div>
      </Page>

      <footer className={classes.footer}>
        <Box lg={12} display="flex" alignItems="center" className={classes.footer_cont}>
          <div className={classes.footer_text}>
            <Typography
              children={`Versión ${BUILD_VERSION}`}
              variant="caption" />
          </div>
          <div className={classes.cont_policies}>
            <div className={classes.footer_text}>
              <Link to="https://dynamicore.io/terminos-y-condiciones" variant="external">
                <Typography
                  children="Términos y condiciones"
                  variant="caption"
                />
              </Link>
            </div>
            <div className={classes.footer_text}>
              <Link to="https://dynamicore.io/aviso-de-privacidad" variant="external">
                <Typography
                  children="Aviso de privacidad"
                  variant="caption"
                />
              </Link>
            </div>
          </div>
        </Box>
      </footer>

    </div>
  );
};

LayoutAuth.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default LayoutAuth;
