import {
  utils,
  write,
  writeFile,
} from 'xlsx';

const BOOK_TYPE = {
  xlsx: {
    fileExt: '.xlsx',
  },
  xlsm: {
    fileExt: '.xlsm',
  },
  xlsb: {
    fileExt: '.xlsb',
  },
  biff8: {
    fileExt: '.xls',
  },
  biff5: {
    fileExt: '.xls',
  },
  biff2: {
    fileExt: '.xls',
  },
  xlml: {
    fileExt: '.xls',
  },
  ods: {
    fileExt: '.ods',
  },
  fods: {
    fileExt: '.fods',
  },
  csv: {
    fileExt: '.csv',
  },
  txt: {
    fileExt: '.txt',
  },
  sylk: {
    fileExt: '.sylk',
  },
  html: {
    fileExt: '.html',
  },
  dif: {
    fileExt: '.dif',
  },
  dbf: {
    fileExt: '.dbf',
  },
  rtf: {
    fileExt: '.rtf',
  },
  prn: {
    fileExt: '.prn',
  },
  eth: {
    fileExt: '.eth',
  },
};

function calcAutofilter(data) {
  const [fields = []] = data;
  const char = (numCol) => String.fromCharCode(64 + numCol);
  const alphabetRepetitions = Math.trunc(fields.length / 26);

  let autofilter = 'A1:';
  if (alphabetRepetitions > 0) {
    autofilter += char(alphabetRepetitions);
  }

  autofilter += `${char(fields.length - alphabetRepetitions * 26)}${data.length}`;

  return autofilter;
}

function fitToColumn(arrayOfArray) {
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(...arrayOfArray.map((a2) => a2[i]
      ? a2[i].toString().length
      : 0)),
  }));
}

/**
 *
 */
export default class Excel {
  workbook;

  /**
   *
   * @param {*} sheets
   * @param {*} props
   */
  constructor(sheets = [], props = null) {
    // Create Excel woorkbook
    this.workbook = utils.book_new();
    this.workbook.Props = {
      Author: props?.author,
      Title: props?.title,
      CreatedDate: props?.createAt || new Date(),
    };

    // Add sheets
    sheets.forEach((sheet) => {
      const {
        autofilter,
        autoFitColumns,
        colLengths,
        name,
        data,
      } = sheet;
      this.workbook.SheetNames.push(name);
      this.workbook.Sheets[name] = utils.aoa_to_sheet(data);

      if (autofilter) {
        this.workbook.Sheets[name]['!autofilter'] = {
          ref: calcAutofilter(data),
        };
      }

      if (colLengths) {
        this.workbook.Sheets[name]['!cols'] = colLengths.map((length) => ({
          wch: length || 10,
        }));
      }

      if (autoFitColumns) {
        this.workbook.Sheets[name]['!cols'] = fitToColumn(data);
      }
    });
  }

  /**
   *
   * @param {*} type
   * @param {*} bookType
   * @returns
   */
  get(type = 'file', bookType = 'xlsx') {
    return write(this.workbook, {
      bookType,
      type,
    });
  }

  /**
   *
   * @param {*} filename
   * @param {*} bookType
   * @returns
   */
  download(filename = 'workbook', bookType = 'xlsx') {
    return writeFile(
      this.workbook,
      `${filename}${BOOK_TYPE[bookType]?.fileExt}`,
      {
        bookType,
      },
    );
  }
}
