import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import {
  Header,
  Page,
} from 'components';
import { Alert } from '@material-ui/lab';

function Error404View({ permission }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.error_403',
  });
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (<Page maxWidth="lg" title={t('title')}>

    <Header />

    <Box mt={2}>
      <Card>
        <CardContent>

          <Typography
            align="center"
            color="textPrimary"
            children={t('header')}
            variant={mobileDevice ? 'h4' : 'h1'}
          />

          {process.env.NODE_ENV === 'development' && (<Box mt={6}>
            <Alert severity="error">
              You don't have permission to <b>{permission}</b>
            </Alert>
          </Box>)}

          <Box mt={6} display="flex" justifyContent="center">
            <Button
              children={t('actions.go_home')}
              color="primary"
              component={RouterLink}
              to="/"
              variant="contained"
            />
          </Box>

        </CardContent>
      </Card>
    </Box>

  </Page>);
}

Error404View.propTypes = {
  permission: PropTypes.string,
};

export default Error404View;
