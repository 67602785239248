import {
  alpha,
  makeStyles,
} from '@material-ui/core';

export default makeStyles((theme) => ({
  tableHead: {
    color: '#ffffff',
  },
  containerHeaderTextLeft: {
    width: '100%',
    textAlign: 'left',
  },
  containerHeaderTextCenter: {
    width: '100%',
    textAlign: 'center',
  },
  containerHeaderTextRight: {
    width: '100%',
    textAlign: 'right',
  },
  tableBodyCell: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right !important',
    },
  },
  primary: {
    color: theme.palette.primary.main,
  },
  primaryBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  secondaryBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  errorBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.7),
    },
  },
  success: {
    color: theme.palette.success.main,
  },
  successBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.7),
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  warningBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.7),
    },
  },
}));
