import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { ConfirmationNumberOutlined as ConfirmationNumberOutlinedIcon } from '@material-ui/icons';

function Ticket() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'system.menu.tickets',
  });

  return (<>
    <Tooltip title={t('tooltip')}>
      <IconButton
        children={<ConfirmationNumberOutlinedIcon />}
        color="inherit"
        href="https://soporte.dynamicore.io/"
        target="_blank"
      />
    </Tooltip>
  </>);
}

export default Ticket;
