import { createElement } from 'react';

import { Label } from 'components';
import { useCatalogTranslation } from 'hooks';
import {
  dateFormat,
  dateTimeFormat,
  numberFormat,
} from 'utils/formater';
import { pickValue } from 'utils/objects';

function useTable(config) {
  const catalogTranslation = useCatalogTranslation();

  const getRows = function (headers, data = [], extras) {
    const rows = Array.isArray(data) ? data : [];

    return rows.map((row, index) => ({
      data: row,
      id: pickValue(row, config?.id || 'id') ?? index,
      values: headers.map((header) => {
        const align = header?.align || 'center';
        let label = typeof header?.get === 'function'
          ? header.get(row)
          : pickValue(row, header.name);

        switch (header.type) {
          default: { break; }

          case 'date': {
            label = dateFormat(label);

            break;
          }

          case 'datetime': {
            label = dateTimeFormat(label, undefined, true);

            break;
          }

          case 'number': {
            let format = undefined;
            let prefix = undefined;

            switch (header?.format) {
              default: { break; }

              case 'currency':
              case 'money': {
                prefix = '$';

                break;
              }

              case 'percentage': {
                format = '0,0.00 %';

                break;
              }
            }

            label = numberFormat(label, prefix, format);

            break;
          }
        }

        if (typeof header.render === 'function') {
          label = header.render(label, row, extras);
        }

        if (header.catalog && typeof label === 'string') {
          label = catalogTranslation(label, ...header.catalog.split('.'));
        }

        switch (header.component) {
          default: { break; }

          case 'label': {
            let color;

            if (typeof header?.color === 'function') {
              color = header.color(pickValue(row, header.name), row);
            } else {
              color = header?.color
                ? header?.color[pickValue(row, header.name)]
                : undefined;
            }

            label = createElement(Label, {
              color,
              children: label,
            });
            break;
          }
        }

        return {
          align,
          label,
        };
      }),
    }));
  };

  const generateTable = function (headers, data = [], extras = {}) {

    const getHeaderLabel = function (header) {
      if (header.label) {
        return header.label;
      } else if (header.name) {
        return typeof config?.translate === 'function'
          ? config?.translate('table.headers.'.concat(header.name))
          : header.name;
      } else {
        return '';
      }
    };

    return {
      headers: (Array.isArray(headers) ? headers : []).map((header) => ({
        label: getHeaderLabel(header),
        align: header.align || 'center',
      })),
      rows: getRows(headers, data, extras),
    };
  };

  return {
    getRows,
    generateTable,
  };
}

export default useTable;
