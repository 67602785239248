/* eslint-disable no-template-curly-in-string */
const MESSAGES = {
  DIGITS_ONLY: 'Debes escribir solo números',
  EMAIL: 'Escribe una dirección de correo electrónico válida',
  INVALID: 'Valor no válido',
  INTEGER: 'El número debe ser entero',
  LESS_THAN: 'Debe ser menor a ${number}',
  LENGTH: 'Debe contener ${length} caracteres',
  MAX: 'Debe ser menor o igual a ${max}',
  MAX_LENGTH: 'Debe contener máximo ${max} caracteres',
  MORE_THAN: 'Debe ser mayor a ${more}',
  MIN: 'Debe ser mayor o igual a ${max}',
  MIN_LENGTH: 'Debe contener mínimo ${min} caracteres',
  MIN_ITEMS: 'Debe contener al menos ${min} elemento',
  NOPE: 'Este campo debe ser diferente',
  NUMBER: 'Debe ser un número válido',
  PASSWORD: 'La contraseña debe contener al menos 8 caracteres, mayúsculas y minúsculas',
  POSITIVE: 'Debe ser un número positivo',
  REQUIRED: 'Campo requerido',
  URL: 'Escribe una URL válida',
  USERNAME_PHONE_EMAIL: 'Escriba un teléfono o correo electrónico válido',
  TYPE_ARRAY: 'Selecciona un valor válido',
  TYPE_ERROR_NUMBER: 'Selecciona un elemento válido',
};

export default MESSAGES;
