import { lazy } from 'react';

import PublicFormsLayout from 'layouts/PublicFormsLayout';

const parentPath = '/public';

const publicRoutes = {
  path: parentPath,
  layout: PublicFormsLayout,
  routes: [
    {
      component: lazy(() => import('views/Public/Integrations/Betaleasing')),
      exact: true,
      path: `${parentPath}/betaleasing`,
    },
    {
      component: lazy(() => import('views/Public/Persons/FormOld')),
      exact: true,
      path: `${parentPath}/people/form/:type`,
    },
    {
      component: lazy(() => import('views/Public/Persons/Form')),
      exact: true,
      path: `${parentPath}/people/form_new/:type`,
    },
    {
      component: lazy(() => import('views/Public/Persons/Sat/AccessKeys')),
      exact: true,
      path: `${parentPath}/people/sat/keys/:token`,
    },
    {
      component: lazy(() => import('views/Public/Integrations/Enpagos')),
      exact: true,
      path: `${parentPath}/integrations/enpagos`,
    },
    {
      component: lazy(() => import('views/Public/Integrations/EnpagosHogar')),
      exact: true,
      path: `${parentPath}/integrations/enpagos_hogar`,
    },
    {
      component: lazy(() => import('views/Errors/Error404')),
    },
  ],
};

export default publicRoutes;
