import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const configFormSchema = Yup.object().shape({
  campaigns: Yup
    .object()
    .shape({
      googleTagId: Yup.string(),
    })
    .default({}),
  footer: Yup
    .object()
    .shape({
      legend: Yup.string(),
    })
    .default({}),
  success: Yup
    .string()
    .url(),
  error: Yup
    .string()
    .url(),
}).default({});

const configSchema = Yup.object().shape({
  id: Yup
    .object()
    .shape({
      fieldname: Yup.string(),
    })
    .default({}),
  evaluations: Yup
    .array()
    .of(Yup.object().shape({
      field: Yup.string(),
      is_valid: Yup.boolean(),
      is_verified: Yup.boolean(),
    }))
    .default([]),
  flows: Yup
    .array().of(Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
    }))
    .default([]),
  forms: Yup
    .object()
    .shape({
      public: configFormSchema,
    })
    .default({
      public: {},
    }),
  fullname: Yup
    .string()
    .nullable()
    .default(null),
  modules: Yup
    .mixed()
    .default({}),
  options: Yup.object().shape({
    hidden: Yup.object({
      active: Yup.boolean().default(false),
    }),
    massiveLoad: Yup.object({
      active: Yup.boolean().default(false),
    }),
  }),
  sort: Yup
    .array()
    .of(Yup.object().shape({
      fieldname: Yup
        .string(),
      fieldtype: Yup
        .string()
        .oneOf([
          'date',
          'int',
          'text',
        ])
        .default('text'),
      type: Yup
        .number()
        .integer()
        .oneOf([-1, 0, 1]),
    }))
    .default([]),
  status: Yup
    .array()
    .of(Yup.object().shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
    }))
    .default([]),
  unique: Yup
    .array()
    .of(Yup.object().shape({
      fieldname: Yup.string(),
    }))
    .default([]),
});

const objGroupTab = {
  name: Yup
    .string()
    .trim()
    .required(MESSAGE.REQUIRED),
  order: Yup
    .number()
    .integer()
    .positive()
    .required(MESSAGE.REQUIRED),
  value: Yup
    .string()
    .trim(),
};

const tabsSchema = Yup.object().shape({
  ...objGroupTab,
  groups: Yup
    .array()
    .of(Yup.object().shape(objGroupTab))
    .default([]),
});

const validateSchema = Yup
  .object()
  .shape({
    max: Yup
      .number()
      .nullable(true)
      .transform((value) => (isNaN(value) || value === null || value === undefined)
        ? null
        : value),
    min: Yup
      .number()
      .nullable(true)
      .transform((value) => (isNaN(value) || value === null || value === undefined)
        ? null
        : value),
    required: Yup
      .boolean()
      .default(false),
  });

export const templateFormSchema = Yup.object().shape({
  breakpoints: Yup
    .number()
    .integer()
    .max(12)
    .min(1)
    .default(6),
  displayname: Yup
    .string()
    .trim()
    .required(MESSAGE.REQUIRED),
  displaytype: Yup
    .string()
    .lowercase()
    .trim()
    .required(MESSAGE.REQUIRED),
  field: Yup
    .number()
    .integer(),
  fieldname: Yup
    .string()
    .lowercase()
    .trim(),
  form: Yup
    .object()
    .shape({
      internal: Yup
        .object()
        .shape({
          add: Yup.boolean().default(true),
          edit: Yup.boolean().default(true),
        }),
      external: Yup
        .object()
        .shape({
          add: Yup.boolean().default(true),
          edit: Yup.boolean().default(true),
        }),
    }),
  format: Yup
    .string()
    .lowercase()
    .trim(),
  group: Yup
    .string()
    .required(MESSAGE.REQUIRED),
  isHeader: Yup
    .boolean()
    .default(false),
  mapping: Yup
    .mixed()
    .transform((value) => Array.isArray(value)
      ? value
      : Object
        .entries(value)
        .map(([label, fieldname]) => ({
          fieldname,
          label,
        }))),
  options: Yup
    .mixed()
    .transform((value) => Array.isArray(value)
      ? value
      : Object
        .entries(value || {})
        .map(([id, name]) => ({
          id,
          name,
        }))),
  placeholder: Yup
    .string()
    .optional(),
  score: Yup
    .number()
    .integer()
    .positive(),
  sort: Yup
    .number()
    .integer()
    .oneOf([-1, 0, 1])
    .default(0),
  tab: Yup
    .string()
    .required(MESSAGE.REQUIRED),
  type: Yup
    .string()
    .required(MESSAGE.REQUIRED),
  validate: validateSchema,
  vars: Yup
    .object()
    .shape({
      catalog: Yup
        .string()
        .trim(),
      element: Yup
        .string()
        .trim()
        .when('catalog', {
          is: (str) => str && str.length,
          then: Yup
            .string()
            .trim()
            .required(MESSAGE.REQUIRED),
        }),
    }),
  view: Yup
    .object()
    .shape({
      details: Yup.boolean().default(true),
    })
    .default({}),
});

export const peopleTypesFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    allow_open_account: Yup
      .string()
      .oneOf(['0', '1'])
      .default('0'),
    base: Yup
      .number()
      .optional(),
    config: configSchema,
    description: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    group_type: Yup
      .number()
      .default(0),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    tabs: Yup
      .array()
      .of(tabsSchema)
      .default([]),
    template: Yup
      .array()
      .of(templateFormSchema)
      .default([]),
    url: Yup
      .string()
      .url()
      .optional(),
  });

export const peopleAddDefinedTemplateFormSchema = Yup
  .object()
  .shape({
    description: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED)
      .transform((value) => value.replace(/'/g, '')),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED)
      .transform((value) => value.replace(/'/g, '')),
  });
