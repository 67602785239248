import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { PortableWifiOff as PortableWifiOffIcon } from '@material-ui/icons';

function Network() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'system.network',
  });

  return (<Tooltip title={t('offline')}>
    <PortableWifiOffIcon
      color="error"
    />
  </Tooltip>);
}

export default Network;
