import {
  HAS_ERROR,
  IS_LOADING,
  SET_DATA,
  SET_LAST_UPDATE,
} from 'redux/constants/publicForms';

export const initialState = {
  error: null,
  isLoading: false,
  lastUpdate: null,
  data: {
    company: {},
  },
};

function publicFormsReducer(state = initialState, action) {
  const { payload = {}, type } = action;
  const {
    error,
    isLoading,
    lastUpdate,
    data,
  } = payload;

  switch (type) {
    default: return state;

    case HAS_ERROR:
      return {
        ...state,
        error,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading,
      };

    case SET_DATA:
      return {
        ...state,
        data,
      };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate,
      };
  }
}

export default publicFormsReducer;
