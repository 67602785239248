import PropTypes from 'prop-types';

export const DEFAULT_PROPS = {
  actions: [],
  color: 'primary',
  onChange: () => { },
  size: 'medium',
  variant: 'contained',
};

export const PROP_TYPES = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    endIcon: PropTypes.element,
    fn: PropTypes.func,
    label: PropTypes.string,
    startIcon: PropTypes.element,
    value: PropTypes.string,
  })),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  onChange: PropTypes.func,
  pb: PropTypes.number,
  pt: PropTypes.number,
  size: PropTypes.oneOf([
    'large',
    'medium',
    'small',
  ]),
  value: PropTypes.string,
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'text',
  ]),
  width: PropTypes.number,
};
