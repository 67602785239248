import {
  Ballot as BallotIcon,
  MoneyOff as MoneyOffIcon,
} from '@material-ui/icons';

import navAmlItems from './aml';

const items = [
  {
    icon: BallotIcon,
    items: [
      {
        href: '/condusef/redeco',
        module: 'condusef',
        title: 'REDECO',
      },
      {
        href: '/condusef/reune',
        module: 'condusef',
        title: 'REUNE',
      },
    ],
    module: 'condusef',
    title: 'condusef',
  },
  {
    icon: MoneyOffIcon,
    items: navAmlItems,
    module: 'pld',
    title: 'aml',
  },
];

export default items;
