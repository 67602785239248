import {
  colors,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
    overflow: 'hidden',
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    backgroundColor: 'transparent !important',
    boxShadow: '0 0 0 0 rgb(63 63 68 / 5%), 0 0 0 0 rgb(63 63 68 / 15%)',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    height: '600px',
    '&:nth-child(2)': {
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'end',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
  },
  content_1: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: '35px',
    },
  },
  content_2: {
    width: '100%',
    '& > div': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '370px',
      marginTop: 0,
    },
  },
  contentForm: {
    width: '438px',
    height: '570px',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0px 8px 28px -6px #0B3569',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      height: 'auto',
    },
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bg: {
    width: '100%',
    height: 'calc(100vh - 80px)',
    minHeight: '760px',
    backgroundImage: 'url(/static/images/bg-auth.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  bgFullHeight: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 80px)',
    },
  },
  waves: {
    backgroundImage: 'url(/static/images/waves.svg)',
    backgroundRepeat: 'repeat-x',
    bottom: 0,
    height: '100vh',
    left: 0,
    position: 'absolute',
    width: '23041.8px',
    zIndex: 0,
  },
  '@keyframes wave': {
    '0%': {
      marginLeft: 0,
    },
    '100%': {
      marginLeft: '-5761px',
    },
  },
  hi: {
    fontSize: '48px',
    color: '#0b3569',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  welcomeLogin: {
    fontSize: '34px',
    color: '#0b3569',
  },
  subTitle: {
    marginTop: '25px',
    fontSize: '34px',
    lineHeight: '34px',
    color: '#0b3569',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  welcome: {
    fontSize: '34px',
    color: '#2c6bdd',
    '& > span': {
      color: '#0b3569',
    },
  },
  back_footer: {
    position: 'absolute',
    width: '100%',
    height: '80px',
    bottom: '-79px',
    left: 0,
    display: 'block',
    backgroundColor: '#0b6bdd',
    zIndex: 0,
  },
  footer: {
    position: 'relative',
    width: '100%',
    height: '80px',
    left: 0,
    bottom: 0,
    backgroundColor: '#0b3569',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
    },
  },
  footer_cont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 24px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1266px',
      padding: '0 24px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '1266px',
      padding: '0 24px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },
  footer_text: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  cont_policies: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& a': {
      color: '#a7d3ff',
      textDecoration: 'none',
    },
    '& div': {
      marginLeft: '25px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 12px 10px',
      },
    },
  },
  center: {
    textAlign: 'center',
  },
  space: {
    width: '100%',
    height: '50px',
  },
  header_imagotipo: {
    position: 'relative',
    display: 'block',
    paddingTop: '20px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  header_isotipo: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  space_inter_recovery_sended: {
    height: '200px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  space_inter_recovery_sended_no: {
    height: '200px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  space_top_recovery_forms: {
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      height: '0px',
    },
  },
  container_icons: {
    position: 'absolute',
    width: '94%',
    height: '83%',
    bottom: '10%',
    left: 0,
    margin: '0 3%',
    display: 'block',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon_1: {
    position: 'absolute',
    width: '140px',
    height: '140px',
    top: 0,
    right: 0,
    backgroundImage: 'url(/static/images/icons-auth/icon-1.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_2: {
    position: 'absolute',
    width: '140px',
    height: '140px',
    top: '70%',
    left: '35%',
    backgroundImage: 'url(/static/images/icons-auth/icon-2.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_3: {
    position: 'absolute',
    width: '100px',
    height: '100px',
    top: '55%',
    left: '10%',
    backgroundImage: 'url(/static/images/icons-auth/icon-3.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_4: {
    position: 'absolute',
    width: '100px',
    height: '100px',
    top: '85%',
    right: 0,
    backgroundImage: 'url(/static/images/icons-auth/icon-4.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_5: {
    position: 'absolute',
    width: '80px',
    height: '80px',
    top: '80%',
    left: '0%',
    backgroundImage: 'url(/static/images/icons-auth/icon-5.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_1: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    top: '30%',
    right: '0%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-1.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_2: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    top: '50%',
    left: '0%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-2.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_3: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    top: '90%',
    left: '50%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-3.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_4: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '60%',
    right: '8%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-4.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_5: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '60%',
    left: '30%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-5.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_6: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '90%',
    left: '13%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-6.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_7: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '60%',
    left: '52%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-7.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_8: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '90%',
    left: '27%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-8.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

export default useStyles;
