import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { useIsOnline } from 'hooks';
import { parseIfValid } from 'utils/json';

import NavBar from './NavBar';
import useStyles from './styles';
import TopBar from './TopBar';


function DashboardLayout({ children }) {
  const isOnline = useIsOnline();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'system',
  });

  const init = async function () {
    const activeModules = Object
      .keys(parseIfValid(localStorage.getItem('modules'), false) || {});

    setNavOpen(activeModules.length > 1);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const company = parseIfValid(localStorage.getItem('company'), false);

    if (isOnline) {
      closeSnackbar('network.offline');
    } else if ([3018].includes(company?.id)) {
      enqueueSnackbar(t('network.offline'), {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: <IconButton
          color="primary"
          children={<CloseIcon />}
          onClick={() => closeSnackbar('network.offline')}
        />,
        key: 'network.offline',
        persist: true,
        variant: 'error',

      });
    }
  }, [isOnline]);

  return (
    <div className={classes.root}>
      <TopBar
        isOnline={isOnline}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        openMenu={isNavOpen}
        setNavOpen={() => setNavOpen(!isNavOpen)}
      />

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openMenu={isNavOpen}
      />

      <div className={classes[isNavOpen ? 'wrapper' : 'wrapperCollapsed']}>
        <div className={classes.contentContainer}>
          <div
            children={children}
            className={classes.content}
          />
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
