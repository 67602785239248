import { useState, useEffect } from 'react';

function useIsOnline() {
  const [status, setStatus] = useState(navigator.onLine);

  useEffect(() => {
    window.ononline = function () {
      console.debug('Connected to network.');
      setStatus(true);
    };

    window.onoffline = function () {
      console.debug('Network connection lost.');
      setStatus(false);
    };
  }, [status]);

  return status;
}

export default useIsOnline;
