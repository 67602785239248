import { jwtDecode } from 'jwt-decode';

import Dynamicore, { SERVICES } from 'services/dynamicore';
import { AWS_USER_POOLS_WEB_CLIENT_ID } from 'settings/aws';
import { parseIfValid } from 'utils/json';

async function adminEnabledUser(username, enabled) {
  const user = await Dynamicore(SERVICES.USERS_ENABLE_DISABLE)
    .post({
      username,
      enabled: enabled ? 1 : 0,
    });

  return user;
}

async function getUser(username) {
  const user = await Dynamicore(SERVICES.USERS_GET_USER)
    .post({
      username,
    });

  return user;
}

function getCurrentSessionData() {
  const payload = {};
  let storageKey = `CognitoIdentityServiceProvider.${AWS_USER_POOLS_WEB_CLIENT_ID}`;

  storageKey += `.${localStorage.getItem(`${storageKey}.LastAuthUser`)}`;

  [
    'accessToken',
    'idToken',
    'refreshToken',
    'userData',
  ].forEach((key) => {
    payload[key] = parseIfValid(localStorage.getItem(`${storageKey}.${key}`));
  });

  if (payload?.userData?.UserAttributes) {
    payload.userData.UserAttributes = Object
      .fromEntries((payload.userData?.UserAttributes || [])
        .map(({ Name, Value }) => [
          Name,
          Value,
        ]));
  }

  try {
    payload.decodeAccessToken = jwtDecode(payload.accessToken);
  } catch (e) {
    payload.decodeAccessToken = null;
  }

  return payload;
}

async function updateUserAttributes(username, attributes = {}) {
  const payload = {
    ...attributes,
    sub: undefined,
  };

  const user = await Dynamicore(SERVICES.USERS_UPDATE_ATTRIBUTES)
    .put({
      username,
      attributes: payload,
    });

  return user;
}

export {
  adminEnabledUser,
  getCurrentSessionData,
  getUser,
  updateUserAttributes,
};
