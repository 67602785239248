import {
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

export const dashboardStatsFormSchema = object().shape({
  name: string()
    .required(MESSAGES.REQUIRED),
  days: number()
    .min(1, MESSAGES.MIN_LENGTH)
    .max(90, MESSAGES.MAX_LENGTH)
    .required(MESSAGES.REQUIRED),
  id: number()
    .min(1, MESSAGES.MIN_LENGTH)
    .required(MESSAGES.REQUIRED),
});
