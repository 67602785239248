import { USERS_PERMISSIONS } from 'settings';
import { pickValue, setPickedKeyValue } from 'utils/objects';

import { parseIfValid } from './json';

export function encode(permisssions, company = '*') {
  const definition = [];

  Object.entries(permisssions).forEach(([keyApp, valApp]) => {
    Object.entries(valApp).forEach(([keyModule, valModule]) => {
      Object.entries(valModule).forEach(([keyAction, valAction]) => {
        const actions = [];

        Object.keys(valAction).forEach((name) => {
          if (valAction[name]) {
            actions.push(`${keyApp}_${keyModule}_${keyAction}:${name}`);
          }
        });

        if (actions.length > 0) {
          definition.push({
            Action: actions,
            Effect: 'Allow',
            Resource: `dcore:${company}:${keyModule}:*`,
          });
        }
      });
    });
  });

  return definition;
}

export function decode(statements = []) {
  const obj = {};

  statements.forEach((item) => {
    (item.Action || []).forEach((action) => {
      setPickedKeyValue(
        obj,
        action.split(':').join('_'),
        true,
        '_',
      );
    });
  });

  return obj;
}

export function hasPermissions(permission, haveAny = false, app = USERS_PERMISSIONS) {
  const permissions = Array.isArray(permission)
    ? permission
    : [permission === 'pld.select.listPld' ? 'pld.select' : permission];

  try {
    const activeModules = Object
      .keys(parseIfValid(localStorage.getItem('modules'), false) || {});
    const moduleValidation = permissions
      .map((item) => {
        const [moduleName] = item.split('.');

        return activeModules.includes(moduleName);
      });

    if (!moduleValidation.some((item) => item)) {
      return false;
    }

    // TODO: Eliminar para activar los permisos de usuarios
    const company = parseIfValid(localStorage.getItem('company'), {});
    const newRolesVersion = [2987, 3018, 3027, 3081, 3089];

    if (company?.id < 3100 && !newRolesVersion.includes(company?.id)) {
      return true;
    }
    // TODO:End

    const role = parseIfValid(localStorage.getItem('permissions'), false) || [];
    const statements = decode(role);

    if (!!pickValue(statements, '*.*')) {
      return true;
    }

    return permissions
      .map((item) => !!pickValue(statements, `${app}.${item}`))[haveAny
        ? 'some'
        : 'every']((item) => item);
  } catch (error) {
    console.error(error.message);

    return false;
  }
};
