import {
  HAS_ERROR,
  IS_LOADING,
  SET_LAST_UPDATE,
} from 'redux/constants/dashboard';

const initialState = {
  error: null,
  isLoading: false,
  lastUpdate: null,
};

function dashboardRedurer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    default: return state;

    case HAS_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: payload.lastUpdate,
      };
  }
}

export default dashboardRedurer;
