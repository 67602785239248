import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from 'redux/constants/auth';

const initialState = {
  logedIn: false,
  isFeching: false,
  error: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFeching: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogedIn: true,
        isFeching: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFeching: false,
        error: action.error,
      };
    case LOGOUT:
      return {
        isFetching: false,
        isLogedIn: false,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
