import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PostAdd as PostAddIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';

import {
  ButtonGroup,
  DynamicForm,
  Label,
  Modal,
} from 'components';
import { MIME_TYPES } from 'constants/files';
import { getSignedUrl, upload } from 'utils/aws/s3';
import { setPickedKeyValue } from 'utils/objects';

import { DEFAULT_PROPS, PROP_TYPES } from './constants';
import useStyles from './styles';

const EXT_IMAGES = MIME_TYPES
  .filter((option) => option.type === 'image')
  .map((option) => option.name) || [];

function DocumentControl(props) {
  const { i18n, t } = useTranslation('translation', {
    keyPrefix: 'components.forms.document_control',
  });
  const {
    error,
    events = {},
    label,
    name: controlName,
    placeholder,
    onChange,
    settings: {
      awsMetadata = {},
      hasMetadata = false,
      readOnly = false,
      route = 'tmp/',
    } = {},
  } = props;
  let { value = [] } = props;

  if (typeof value === 'string') {
    try {
      value = JSON.parse(value) || [];
    } catch (e) {
      value = [];
    }
  }

  const classes = useStyles();
  const [currentView, setCurrentView] = useState('preview');
  const [documents, setDocuments] = useState(Array.isArray(value) ? value : []);
  const [hasError, setHasError] = useState();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState();
  const [nameFile, setNameFile] = useState();
  const [open, setOpen] = useState();
  const [signedDocuments, setSignedDocuments] = useState([]);

  const handlePreview = async function (document) {
    const signedUrl = await getSignedUrl(document.url, 120);
    const extension = ((document?.url ?? '').split('.').pop() || '').toLowerCase();
    const mimeType = MIME_TYPES.find((option) => option.name === extension);

    setModal({
      canFullScreen: true,
      open: true,
      title: 'preview',
      download: true,
      data: {
        ...document,
        mimeType,
        signedUrl,
      },
    });
  };

  const handleShowModal = (item) => {
    setNameFile('');
    setFilesToUpload([]);

    setModal({
      open: true,
      title: 'select_files',
      download: false,
      data: {},
      item,
    });
  };

  const handleFileSave = async () => {
    if (!nameFile) {
      addError('Debes escribir el nombre del archivo');

      return;
    }

    setLoading(true);
    const uploaded = [];

    for await (const fileToUpload of filesToUpload) {
      const ext = ((fileToUpload?.name ?? '').split('.').pop() || '').toLowerCase();
      const s3File = await upload(
        `${route}${new Date().getTime()}.${String(Math.random()).substring(
          2,
          7,
        )}${ext ? `.${ext.toLowerCase()}` : ''}`,
        fileToUpload,
        awsMetadata,
      );

      uploaded.push({
        active: true,
        metadata: {},
        name: `${nameFile || ''}${filesToUpload.length > 1 ? (` ${uploaded.length + 1}`) : ''}`,
        uploaded: new Date().getTime(),
        url: s3File.Location,
      });
    };

    setDocuments([
      ...documents,
      ...uploaded,
    ]);
    setHasError('');
    setModal({
      open: false,
    });
  };

  const handleShowModalDelete = (index) => {
    setOpen({
      show: true,
      index,
    });
  };

  const handleDelete = (index) => {
    setOpen({
      show: false,
      index,
    });
    const tmpImg = [...documents.filter((_) => _.active)];
    tmpImg[index].active = false;

    setDocuments(tmpImg);
  };

  const handleDownload = async function (image) {
    const url = await getSignedUrl(image.url, 120);

    window.open(url);
  };

  const loadSigned = async () => {
    const tmpDoc = [];
    if (documents?.length > 0) {
      for await (const document of documents.filter((_) => _.active)) {
        const signedUrl = await getSignedUrl(document.url, 120);
        tmpDoc.push(signedUrl);
      }
      setSignedDocuments(tmpDoc);
    }
  };

  const addError = function (msg = '') {
    setHasError(msg);

    document.getElementById('modalInputFileName').focus();
  };

  useEffect(() => {
    const target = {
      name: controlName,
      value: documents,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }

    loadSigned();
    setLoading(false);
  }, [documents]);

  return (
    <>
      <Modal
        actions={[
          {
            color: 'primary',
            icon: <CloseIcon />,
            label: t('modal.actions.no'),
            fn: function () {
              setOpen({
                show: false,
              });
            },
          },
          {
            color: 'error',
            icon: <CheckIcon />,
            label: t('modal.actions.yes'),
            fn: function () {
              handleDelete(open?.index);
            },
          },
        ]}
        maxWidth="xs"
        open={open?.show}
        title={t('messages.delete')}
      >
        {(documents[open?.index] && documents[open?.index]?.metadata?.is_verified) && (
          <Alert
            children="Este documento esta verificado"
            severity="warning"
          />
        )}
      </Modal>

      <Modal
        actions={[
          {
            color: 'primary',
            hidden: readOnly || currentView !== 'metadata',
            icon: <EditIcon />,
            label: t('edit'),
            fn: function () {
              const objDocuments = structuredClone(documents);
              const idx = objDocuments.findIndex((doc) => doc.url === modal?.data?.url);

              objDocuments[idx].metadata = modal?.data?.metadata ?? {};

              setDocuments(objDocuments);
              setModal({
                open: false,
              });
            },
          },
          {
            color: 'primary',
            icon: <CloudDownloadIcon />,
            label: t('modal.actions.download'),
            hidden: !modal?.download || currentView === 'metadata',
            fn: () => handleDownload(modal?.data),
          },
          {
            color: 'primary',
            disabled: !filesToUpload.length || loading,
            icon: loading ? <CircularProgress size={20} /> : <CloudUploadIcon />,
            label: t('modal.actions.upload'),
            hidden: modal?.download,
            fn: handleFileSave,
          },
        ]}
        canFullScreen={modal?.canFullScreen}
        events={{
          onClose: !loading ? () => {
            setHasError('');
            setModal();
          } : undefined,
        }}
        open={modal?.open}
        title={t(modal?.title)}
      >
        {(!modal?.item && hasMetadata) && (<ButtonGroup
          actions={[
            {
              label: t('preview'),
              value: 'preview',
            },
            {
              label: t('metadata'),
              value: 'metadata',
            },
          ]}
          onChange={setCurrentView}
          pb={2}
          value={currentView}
          width={180}
        />)}

        {currentView === 'metadata' && (<>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell children="Parámetro" />
                <TableCell children="Valor" />
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                {
                  label: 'Está verificado',
                  name: 'is_verified',
                  type: 'boolean',
                },
                {
                  label: 'Fecha de vigencia',
                  name: 'validity_date',
                  type: 'date',
                },
              ].map((item, index) => (<TableRow key={index}>
                <TableCell children={item.label} />
                <TableCell>
                  {(function () {
                    let paramValue;
                    if (modal?.data?.metadata !== undefined) {
                      paramValue = modal?.data?.metadata[item.name];
                    }

                    switch (item.type) {
                      default: break;

                      case 'boolean': {
                        if (readOnly) {
                          paramValue = (<Label
                            children={paramValue ? 'Si' : 'No'}
                            color={paramValue ? 'success' : 'error'}
                          />);
                        } else {
                          paramValue = (<DynamicForm
                            fields={{
                              events: {
                                onChange: function (event) {
                                  const objModal = structuredClone(modal);

                                  setPickedKeyValue(objModal, 'data.metadata.is_verified', event.value);
                                  setModal(objModal);
                                },
                              },
                              type: 'switch',
                              value: paramValue,
                            }}
                          />);
                        }
                        break;
                      }

                      case 'date': {
                        if (!readOnly) {
                          paramValue = (<DynamicForm
                            fields={{
                              events: {
                                onChange: function (event) {
                                  const objModal = structuredClone(modal);
                                  const [date] = event.value.toISOString().split('T');

                                  setPickedKeyValue(objModal, 'data.metadata.validity_date', date);
                                  setModal(objModal);
                                },
                              },
                              type: 'date',
                              value: new Date(`${paramValue}T00:00:00.000`),
                            }}
                          />);
                        }
                        break;
                      }
                    }

                    return paramValue;
                  })()}
                </TableCell>
              </TableRow>))}
            </TableBody>
          </Table>
        </>)}

        <Box display={currentView === 'preview' ? undefined : 'none'}>
          {modal?.data?.mimeType?.visor === 'image' && (
            <img alt="" width="100%" src={modal?.data?.signedUrl} />
          )}

          {modal?.data?.mimeType?.visor === 'document' && (
            <iframe
              frameborder="0"
              height="100%"
              src={`https://docs.google.com/gview?url=${encodeURIComponent(modal?.data?.signedUrl)}&embedded=true`}
              title="Visor"
              width="100%"
            />
          )}

          {modal?.data?.mimeType?.visor === 'code' && (<>
            Vista previa no disponible
          </>)}
        </Box>

        {modal?.item && (<Grid container>
          <input
            multiple
            id={`file_${modal?.item?.field}`}
            name={modal?.item?.filename}
            type="file"
            style={{
              display: 'none',
            }}
            onChange={({ target }) => {
              const count = target.files.length;
              const files = [];
              for (let i = 0; i < count; i++) {
                files.push(target.files[i]);
              }

              setFilesToUpload([
                ...filesToUpload,
                ...files,
              ]);
            }}
          />
          <Grid item xs={12}>

            {hasError ?
              (<Alert
                children={i18n.exists(hasError) ? t(hasError) : hasError}
                onClose={() => addError()}
                severity="error"
              />)
              : (<Typography
                children={t('name_label')}
                variant="body2"
              />)}
            <TextField
              fullWidth
              id="modalInputFileName"
              name="file_name"
              onChange={({ target }) => setNameFile(target.value)}
              required
              size='small'
              type="text"
              value={nameFile}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12}>
            <Box mt={1} textAlign="center">
              <Tooltip title={t('add_files_tooltip')}>
                <Button
                  children={t('modal.actions.browse_files')}
                  color="primary"
                  disabled={loading}
                  onClick={function () {
                    document.getElementById(`file_${modal?.item?.field}`).click();
                  }}
                  startIcon={<PostAddIcon />}
                  size="medium"
                  variant="contained"
                />
              </Tooltip>
            </Box>
          </Grid>

          {filesToUpload.length > 0 && <Grid item xs={12}>
            <Paper
              style={{
                marginTop: 10,
              }} elevation={2}
            >
              <List dense>
                {filesToUpload.map((fileItem, fileIndex) => (<ListItem
                  button
                  key={fileIndex}
                >
                  <ListItemAvatar
                    children={<Avatar children={<InsertDriveFileIcon />} />}
                  />
                  <ListItemText
                    primary={fileItem.name}
                    secondary={<>
                      <b>{Math.round(fileItem.size / 10.24, 2) / 100} KB</b>
                      {' '}{fileItem.type}
                    </>}
                  />
                  {!loading && (<ListItemSecondaryAction>
                    <Tooltip title={t('delete_document')}>
                      <IconButton edge="end" aria-label="delete">
                        <CancelIcon
                          onClick={function () {
                            setFilesToUpload(filesToUpload.filter((_, i) => i !== fileIndex));
                          }}
                          style={{
                            color: red[500],
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>)}
                </ListItem>))}
              </List>
            </Paper>
          </Grid>}

        </Grid>)}
      </Modal >

      <fieldset style={{
        borderRadius: 5,
        borderColor: error ? 'red' : undefined,
      }}>
        {label && <legend style={{
          marginLeft: 8,
        }}>
          <Typography color="textSecondary" variant="caption">
            <Box style={{
              marginLeft: 5,
              marginRight: 5,
            }}>
              {label}
            </Box>
          </Typography>
        </legend>}
        {placeholder && <Typography color="textPrimary" variant="caption">
          <Box
            children={placeholder}
            style={{
              marginLeft: 5,
              marginRight: 5,
            }}
          />
        </Typography>}
        {error && <Typography color="error" variant="caption">
          <Box
            children={error}
            style={{
              marginLeft: 5,
              marginRight: 5,
            }}
          />
        </Typography>}
        <ImageList
          alignItems="center"
          className={classes.imageList}
          cols={5}
          direction="row"
          justifyContent="center"
        >
          {!readOnly && <IconButton
            className={classes.button}
            onClick={() => handleShowModal(value)}
          >
            <Tooltip
              children={<SvgIcon children={<AddIcon />} />}
              title="Agregar archivo"
            />
          </IconButton>}
          {
            (Array.isArray(documents) ? documents : [])
              .filter((doc) => doc?.active)
              .map((doc, index) => {
                const ext = (doc?.url ?? '').split(/[\s.]+/).pop();

                return (
                  <ImageListItem
                    key={`${doc.name}_${index}`}
                    style={{
                      border: doc?.metadata?.is_verified
                        ? '2px solid #008a00'
                        : undefined,
                      padding: 5,
                      minWidth: 120,
                      cursor: 'pointer',
                    }}
                  >
                    {EXT_IMAGES.includes(ext) ?
                      (<img
                        key={`${doc.name}_${index}_img`}
                        alt={doc.name}
                        src={signedDocuments[index]}
                        onClick={() => handlePreview(doc)}
                        style={{
                          minHeight: 126,
                          width: '100%',
                        }}
                      />) :
                      (<SvgIcon
                        key={`${doc.name}_${index}_icon`}
                        fontSize="large"
                        children={<InsertDriveFileIcon />}
                        onClick={() => handlePreview(doc)}
                        style={{
                          minHeight: 126,
                          width: '100%',
                        }}
                      />)
                    }
                    <ImageListItemBar
                      title={doc.name}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      actionIcon={readOnly
                        ? (doc?.metadata?.is_verified
                          ? (<Tooltip
                            children={<CheckIcon className={classes.verified} />}
                            title="Documento verificado"
                          />)
                          : undefined)
                        : (<Tooltip
                          children={<IconButton
                            aria-label={`star ${doc.name}`}
                            children={<DeleteIcon className={classes.title} />}
                            onClick={() => handleShowModalDelete(index)}
                          />}
                          title={t('delete_document')}
                        />)}
                    />
                  </ImageListItem>
                );
              })
          }
        </ImageList>
      </fieldset>

    </>
  );
}

DocumentControl.defaultProps = DEFAULT_PROPS;
DocumentControl.propTypes = PROP_TYPES;

export default DocumentControl;
