import {
  bool,
  number,
  string,
} from 'prop-types';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import lockImg from 'assets/images/Lock.png';
import mastercardImg from 'assets/images/mastercardLogo.png';
import { numberFormat } from 'utils/formater';

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#213134',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '18px',
    textDecoration: 'none !important',
  },
  cardContainer: {
    backdropFilter: 'blur(47.9147px)',
    background: 'linear-gradient(90deg, #193754 0%, #5A618A 100%)',
    borderRadius: '17.5877px',
    boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)',
    height: '200.78px',
    width: '300px',
  },
  card: {
    padding: '20px 20px',
  },
  textName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '10px',
    color: '#FFFFFF',
    opacity: '0.9',
  },
  textAmount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '10px',
    color: '#FFFFFF',
  },
  amount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFFFFF',
  },
  lastFour: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.34em',
  },
  validity: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    letterSpacing: '0.34em',
  },
  personName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FFFFFF',
  },
  lockImg: {
    display: 'flex',
    justifyContent: 'flex-end !important',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end !important',
    },
  },
  dataCard: {
    marginTop: '20px',
  },
  spaceName: {
    marginTop: '35px',
  },
  mastercardImg: {
    display: 'flex',
    justifyContent: 'flex-end !important',
    paddingTop: '15px',
  },
}));

function CardComponent(props) {

  const classes = useStyles();

  return (<Grid container className={classes.cardContainer}>
    <Grid item className={classes.card}>
      <Grid container>
        <Grid item lg={6} md={6}>
          <Typography
            children="Monto de tarjeta:"
            className={classes.textAmount}
            variant="h6"
          />
          <Typography
            children={numberFormat(props.amount, '$ ')}
            className={classes.amount}
            variant="h6"
          />
        </Grid>
        <Grid item lg={6} md={6} className={classes.lockImg}>
          {props.isBlocked && <img src={lockImg} alt="lock" />}
        </Grid>
        <Grid container className={classes.dataCard}>
          <Grid item lg={12}>
            <Typography
              children={`**** **** **** ${props.last_four}`}
              className={classes.lastFour}
              variant="h6"
            />
            <Typography
              children={props.validity ?? ' '}
              className={classes.validity}
              variant="caption"
            />
          </Grid>
          <Grid item lg={6} className={classes.spaceName}>

            <Typography
              children="Nombre"
              className={classes.textName}
              gutterBottom
              variant="h6"
            />
            <Typography
              children={props.name}
              className={classes.personName}
              variant="h6"
            />

          </Grid>
          <Grid item lg={6} className={classes.mastercardImg}>
            <img
              src={mastercardImg}
              alt="mastercard"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>);
};

CardComponent.defaultProps = {
  amount: 0,
  last_four: '0000',
  isBlocked: false,
};

CardComponent.propTypes = {
  amount: number,
  last_four: string,
  name: string.isRequired,
  isBlocked: bool,
  validity: string,
};

export default CardComponent;
