import {
  SET_TABLE_DATA,
  HAS_ERROR,
  IS_LOADING,
  READ_ALL,
  RESET_ORDER,
  SET_LAST_UPDATE,
  SORT,
} from 'redux/constants/campaigns';

export const initialState = {
  error: null,
  isLoading: false,
  items: {
    rows: [],
    total: 0,
  },
  lastUpdate: null,
  table: {
    headers: [],
    rows: [],
    total: 0,
  },
  order: [],
};

function campaignsRedurer(state = initialState, action) {
  const { payload = {}, type } = action;
  const {
    error,
    isLoading,
    items,
    lastUpdate,
    table,
  } = payload;

  switch (type) {
    default: return state;

    case SET_TABLE_DATA:
    case RESET_ORDER:
    case SORT:
      return {
        ...state,
        table,
      };

    case HAS_ERROR:
      return {
        ...state,
        error,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading,
      };

    case READ_ALL:
      return {
        ...state,
        items,
      };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate,
      };
  }
}

export default campaignsRedurer;
