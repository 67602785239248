import {
  // date,
  number,
  object,
  string,
} from 'yup';

import MESSAGES from '../../constants/messages';


export const redecoComplaintFormSchema = object().shape({
  quejas_cp: number()
    .max(99999, MESSAGES.MAX)
    .min(1, MESSAGES.MIN)
    .required(MESSAGES.REQUIRED),
  estados_id: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_mun_id: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_loc_li: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_col_id: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_tipo_persona: number()
    .oneOf([1, 2])
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_sexo: string()
    .oneOf(['H', 'M'], MESSAGES.TYPE_ERROR_NUMBER)
    .when('quejas_tipo_persona', {
      is: 1,
      then: string().required(MESSAGES.REQUIRED),
    }),
  quejas_edad: number()
    .integer(MESSAGES.INTEGER)
    .positive(MESSAGES.POSITIVE)
    .when('quejas_tipo_persona', {
      is: 1,
      then: number()
        .typeError(MESSAGES.REQUIRED)
        .required(MESSAGES.REQUIRED),
    }),
  quejas_no_trim: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_num: number()
    .typeError(MESSAGES.NUMBER)
    .default(1),
  quejas_folio: string()
    .uppercase()
    .max(50, MESSAGES.MAX_LENGTH)
    .required(MESSAGES.REQUIRED),
  // quejas_fec_recepcion: date()
  //   .required(MESSAGES.REQUIRED),
  medio_id: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  nivel_atencion_id: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  product: string()
    .required(MESSAGES.REQUIRED),
  causas_id: number()
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_pori: string()
    .oneOf(['SI', 'NO'], MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
  quejas_estatus: number()
    .oneOf([1, 2])
    .required(MESSAGES.REQUIRED),
  quejas_respuesta: number()
    .oneOf([1, 2, 3], MESSAGES.TYPE_ERROR_NUMBER)
    .when('quejas_estatus', {
      is: 2,
      then: number().required(MESSAGES.REQUIRED),
    }),
  // quejas_fec_resolucion: date()
  //   .required(MESSAGES.REQUIRED),
  // quejas_fec_notificacion: date()
  //   .required(MESSAGES.REQUIRED),
  quejas_num_penal: number(),
  penalizacion_id: number()
    .oneOf([1, 2, 3], MESSAGES.TYPE_ERROR_NUMBER)
    .typeError(MESSAGES.TYPE_ERROR_NUMBER)
    .required(MESSAGES.REQUIRED),
});
