import {
  alpha,
  makeStyles,
} from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  errorBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.7),
    },
  },
  filterCard: {
    marginBottom: theme.spacing(2),
  },
  footerSeparator: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2px',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  primaryBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  secondaryBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  success: {
    color: theme.palette.success.main,
  },
  successBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.7),
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  warningBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.7),
    },
  },
}));
