import {
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

export const templatesFilesFormSchema = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    channel: number()
      .typeError('Selecciona una opción')
      .required(MESSAGES.REQUIRED),
    data: object()
      .nullable()
      .default({}),
    html: string()
      .default(''),
    name: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    subject: string()
      .trim()
      .required(MESSAGES.REQUIRED),
  });
