import {
  array,
  boolean,
  lazy,
  mixed,
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

const siteSchema = object()
  .shape({
    config: object()
      .shape({
        payment_methods: object()
          .shape({
            cards: array().default([]),
          }),
        security: object()
          .shape({
            global_sign_out: boolean().default(true),
            recommendMFA: boolean().default(false),
          }),
      }),
    favicon: string()
      .url(MESSAGES.URL),
    privacy_notice: string()
      .url(MESSAGES.URL),
    terms_sheet: string()
      .url(MESSAGES.URL),
    title: string()
      .trim(),
  })
  .default({});

export const companyFormSchema = object()
  .shape({
    id: number()
      .integer()
      .positive(),
    name: string()
      .trim()
      .nullable()
      .required(MESSAGES.REQUIRED),
    street_address: string()
      .trim()
      .nullable(),
    city: string()
      .trim()
      .nullable(),
    zipcode: string()
      .trim()
      .nullable(),
    state: string()
      .trim()
      .nullable(),
    country: string()
      .trim()
      .nullable(),
    email: string()
      .email(MESSAGES.EMAIL)
      .required(MESSAGES.REQUIRED),
    public_key: string()
      .nullable(),
    logo: object()
      .shape({
        imagotipoDarkH: string()
          .url(MESSAGES.URL),
        imagotipoHorizontal: string()
          .url(MESSAGES.URL),
      }),
    theme: object()
      .shape({
        primary: string(),
        secondary: string(),
      }),
    site: siteSchema,
  });

const companyParametersConfigSchema = object().shape({
  origin: string()
    .oneOf([
      'internal',
      'external',
    ], MESSAGES.TYPE_ARRAY)
    .required(MESSAGES.REQUIRED),
  service: object().when('origin', {
    is: 'external',
    then: (schema) => schema.shape({
      body: object(),
      method: string()
        .oneOf([
          'GET',
          'POST',
        ], MESSAGES.TYPE_ARRAY)
        .required(MESSAGES.REQUIRED),
      url: string()
        .url(MESSAGES.URL)
        .required(MESSAGES.REQUIRED),
    }),
  }),
}).default({});

export const companyParametersFormSchema = object().shape({
  company: number()
    .integer()
    .positive(),
  config: companyParametersConfigSchema,
  enabled: boolean()
    .default(true),
  id: number()
    .integer()
    .positive(),
  name: string()
    .required(MESSAGES.REQUIRED),
  type_id: number()
    .integer()
    .positive()
    .required(MESSAGES.REQUIRED),
  value: lazy((_, { parent }) => mixed()
    .when({
      is: () => parent?.config?.origin === 'internal',
      then: (schema) => schema
        .required(MESSAGES.REQUIRED),
    })
    .when({
      is: () => parent?.type_id === 3,
      then: () => string().url(MESSAGES.URL)
        .required(MESSAGES.REQUIRED),
    }),
  ),
});
