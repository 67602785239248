import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const templateSchema = Yup
  .object()
  .shape({
    field: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    name: Yup
      .string()
      .trim()
      .lowercase()
      .required(MESSAGE.REQUIRED),
    displayname: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    displaytype: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    format: Yup
      .string()
      .trim(),
    score: Yup
      .number()
      .integer()
      .positive(),
    validate: Yup
      .object()
      .shape({
        required: Yup
          .boolean()
          .default(false),
      }),
    type: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
  });

export const productTypesFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    description: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    alias: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    template: Yup
      .array()
      .of(templateSchema)
      .default([]),
  });
