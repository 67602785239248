import { useLocation } from 'react-router-dom';
import { parse as queryParse } from 'querystring';

import { parseValue } from 'utils/strings';

function useQuery() {
  const { search } = useLocation();
  const parsed = queryParse(search.substring(1));

  const entries = Object
    .entries(parsed)
    .filter(([, val]) => val !== '')
    .map(([key, val]) => [key, parseValue(val)]);

  return Object.fromEntries(entries);
};

export default useQuery;
