import {
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

const configSchema = object().shape({
  columns: object()
    .default({}),
  filters: object()
    .default({}),
  params: object()
    .default({})
    .shape({
      client_type: number()
        .positive()
        .integer(),
      product: number()
        .positive()
        .integer(),
    }),
});

export const reportsFormSchema = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    name: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    description: string()
      .required(MESSAGES.REQUIRED),
    config: configSchema,
  });
