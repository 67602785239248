import {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Popover,
  makeStyles,
  Box,
} from '@material-ui/core';
import { AccessTime as AccessTimeIcon } from '@material-ui/icons';

import CronUI from 'lib/material-ui-cron';
import {
  altMonths,
  altWeekDays,
  months,
  weekDays,
} from '../constants/dates';

const useStyles = makeStyles((theme) => ({
  label: {
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const esLocale = {
  clearButtonText: 'Limpiar',
  dayOption: 'día',
  hourOption: 'hora',
  minuteOption: 'minuto',
  altMonths,
  altWeekDays,
  weekDays,
  months,
  monthOption: 'mes',
  prefixMonths: 'en',
  prefixMonthDays: 'día',
  prefixPeriod: 'Cada',
  prefixWeekDays: 'el día',
  prefixWeekDaysForMonthAndYearPeriod: 'y',
  prefixHours: 'Hora',
  prefixMinutesForHourPeriod: 'en',
  suffixMinutesForHourPeriod: 'minutos',
  yearOption: 'año',
  weekOption: 'semana',
};

function CronControl(props) {
  const popoverRef = useRef(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {
    disabled,
    label,
    name,
    size,
    value,
    onChange,
  } = props;

  const [cronValue, setCronValue] = useState(value);
  const [error, onError] = useState();

  const controlId = `control_${name}_${Math.floor(Math.random() * 100000)}`;

  const handleShowSelector = function () {
    setOpen(true);
  };

  const handleClose = function () {
    setOpen(false);
  };

  useEffect(() => {
    onChange({
      target: {
        name,
        value: cronValue,
      },
    });
  }, [cronValue]);


  return (<>
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth
      size={size}
      variant="outlined"
      ref={popoverRef}
    >
      <InputLabel
        className={classes.label}
        children={label}
        htmlFor={controlId}
        shrink={!!value}
      />
      <OutlinedInput
        readOnly
        id={controlId}
        type="text"
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label=""
              children={<AccessTimeIcon />}
              disabled={disabled}
              onClick={handleShowSelector}
              edge="end"
            />
          </InputAdornment>
        }
      />
    </FormControl>
    <Popover
      open={open}
      anchorEl={popoverRef.current}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: popoverRef?.current?.offsetWidth,
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box mt={3}>
        <CronUI
          displayError={true}
          defaultPeriod="month"
          humanizeLabels={true}
          humanizeValue={true}
          locale={esLocale}
          name={name}
          value={cronValue}
          setValue={setCronValue}
          onError={onError}
        />
      </Box>
    </Popover>
  </>);
}

CronControl.defaultProps = {
  disabled: false,
  onChange: () => { },
  settings: {},
};

CronControl.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'small']),
  settings: PropTypes.shape({
    mode: PropTypes.oneOf(['modal', 'inline', 'input']),
  }),
  value: PropTypes.string,
};

export default CronControl;
