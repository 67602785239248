import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

function TextControl(props) {
  const [revealPassword, setRevealPassword] = useState(false);
  const {
    disabled,
    display,
    error,
    events,
    settings,
    size,
    onBlur,
    onChange,
  } = props;
  let { type } = props;

  const handleChange = (e) => {
    onChange(e);

    if (events.onChange) {
      events.onChange(e?.target);
    }
  };

  if (type === 'password' && settings?.canReveal) {
    display.endIcon = revealPassword
      ? <VisibilityOffIcon />
      : <VisibilityIcon />;
    display.endIconAction = () => setRevealPassword(!revealPassword);
    display.endIconTitle = revealPassword
      ? 'Ocultar'
      : 'Visualizar';
    type = revealPassword ? 'text' : type;
  }

  const Title = display.endIconTitle ? Tooltip : Fragment;

  if (props.isMultiple) {
    const arrFields = Array.isArray(props.value) ? props.value : [props.value];

    return (<Grid md={12} container>
      {arrFields.map((item, index) => {

        return (<>
          <Grid item md={11}>
            <TextField
              disabled={disabled}
              error={error}
              fullWidth={true}
              helperText={props.helperText}
              InputProps={{
                endAdornment: display.endIcon
                  ? (<InputAdornment position="end">
                    {display.endIconAction
                      ? (<Title {...display.endIconTitle
                        ? {
                          title: display.endIconTitle,
                        }
                        : {}}
                      >
                        <IconButton
                          children={display.endIcon}
                          onClick={() => display.endIconAction(props.value)}
                          size="small"
                        />
                      </Title>)
                      : (<SvgIcon
                        children={display.endIcon}
                        color="action"
                        fontSize="small"
                      />)}
                  </InputAdornment>)
                  : null,
                readOnly: settings?.readOnly,
                startAdornment: display.startIcon
                  ? (<InputAdornment position="start">
                    <SvgIcon
                      children={display.startIcon}
                      color="action"
                      fontSize="small"
                    />
                  </InputAdornment>)
                  : null,
              }}
              key={`${props.name}.${index}`}
              label={`${props.label} (${index + 1})`}
              name={`${props.name}.${index}`}
              onBlur={onBlur}
              onChange={handleChange}
              placeholder={props.placeholder}
              size={size}
              type={type}
              value={item}
              variant="outlined"
            />
          </Grid>
          <Grid md={1}>
            <IconButton
              color="primary"
              children={(index === arrFields.length - 1)
                ? <AddIcon />
                : <RemoveIcon />}
              disabled={(item === null || item === '' || item === undefined) && (index === arrFields.length - 1)}
              onClick={function () {
                const target = {};

                if (index === arrFields.length - 1) {
                  target.name = `${props.name}.${index + 1}`;
                  target.value = '';
                } else {
                  target.name = props.name;
                  target.value = [...arrFields];
                  target.value.splice(index, 1);
                }

                handleChange({
                  target,
                });
              }}
            />
          </Grid>
        </>);
      })}
    </Grid >);
  }

  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth={true}
      helperText={props.helperText}
      InputProps={{
        endAdornment: display.endIcon
          ? (<InputAdornment position="end">
            {display.endIconAction
              ? (<Title {...display.endIconTitle
                ? {
                  title: display.endIconTitle,
                }
                : {}}
              >
                <IconButton
                  children={display.endIcon}
                  onClick={() => display.endIconAction(props.value)}
                  size="small"
                />
              </Title>)
              : (<SvgIcon
                children={display.endIcon}
                color="action"
                fontSize="small"
              />)}
          </InputAdornment>)
          : null,
        readOnly: settings?.readOnly,
        startAdornment: display.startIcon
          ? (<InputAdornment position="start">
            <SvgIcon
              children={display.startIcon}
              color="action"
              fontSize="small"
            />
          </InputAdornment>)
          : null,
      }}
      label={props.label}
      multiline={settings?.multiline}
      name={props.name}
      placeholder={props.placeholder}
      rows={settings?.rows}
      rowsMax={settings?.rowsMax}
      size={size}
      type={type}
      variant="outlined"
      value={props.value}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          events.onChange(e?.target);
        }
      }}
    />
  );
}

TextControl.defaultProps = {
  disabled: false,
  display: {},
  error: false,
  events: {},
  onBlur: () => { },
  onChange: () => { },
  type: 'text',
};

TextControl.propTypes = {
  disabled: PropTypes.bool,
  display: PropTypes.shape({
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    endIconAction: PropTypes.func,
    endIconTitle: PropTypes.string,
  }),
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  isMultiple: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    'checkbox',
    'email',
    'hidden',
    'password',
    'radio',
    'text',
  ]),
  value: PropTypes.any,
  settings: {
    multiline: PropTypes.bool,
    readOnly: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
  },
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default TextControl;
