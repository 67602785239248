export const EXTENSIONS = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  PDF: 'pdf',
  PNG: 'png',
  XML: 'xml',
};

export const MIME_TYPES = [
  {
    name: 'gif',
    description: 'Graphics Interchange Format',
    mime: 'image/gif',
    type: 'image',
    visor: 'image',
  },
  {
    name: EXTENSIONS.JPG,
    description: '',
    mime: 'image/jpeg',
    type: 'image',
    visor: 'image',
  },
  {
    name: EXTENSIONS.JPEG,
    description: '',
    mime: 'image/jpeg',
    type: 'image',
    visor: 'image',
  },
  {
    name: EXTENSIONS.PDF,
    description: 'Adobe Portable Document Format',
    mime: 'application/pdf',
    type: 'application',
    visor: 'document',
  },
  {
    name: EXTENSIONS.PNG,
    description: '',
    mime: 'image/png',
    type: 'image',
    visor: 'image',
  },
  {
    name: 'svg',
    description: 'Gráficos Vectoriales',
    mime: 'image/svg+xml',
    type: 'image',
    visor: 'image',
  },
  {
    name: 'tif',
    description: 'Formato de archivo de imagen etiquetado',
    mime: 'image/tiff',
    type: 'image',
    visor: 'image',
  },
  {
    name: 'tiff',
    description: 'Formato de archivo de imagen etiquetado',
    mime: 'image/tiff',
    type: 'image',
    visor: 'image',
  },
  {
    name: 'webp',
    description: 'Imágenes WEBP',
    mime: 'image/webp',
    type: 'image',
    visor: 'image',
  },
  {
    name: EXTENSIONS.XML,
    description: 'XML',
    mime: 'application/xml',
    type: 'application',
    visor: 'code',
  },
];
