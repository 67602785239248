import {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import { DynamicForm } from 'components';
import { TABLES_ITEMS_PER_PAGE } from 'settings';

import { DEFAULT_PROPS, PROP_TYPES } from './constants';
import Filters from './Filters';
import useStyles from './styles';
import { DateControl } from '../DynamicForm/controls';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.action.focus} !important`,
    },
  },
}))(TableRow);

function DataTable(props) {
  const {
    actions,
    filters,
    footers,
    footerSeparator,
    loadData = props.getDataAction,
    headers,
    rows,
    pagination = {},
    searchAction,
    searchActionByDate,
    mt,
    onDeleteFilterDate,
    selectable,
    size,
    stickyHeader,
    subTitle,
    title,
    total,
    offsetTop,
    maxHeightFlag,
    maxHeightValue,
  } = props;

  const {
    disabled: removePagination = false,
    perPageOptions = TABLES_ITEMS_PER_PAGE,
  } = pagination;
  const history = useHistory();
  const containerRef = useRef();
  const classes = useStyles();
  const [limit, setLimit] = useState(perPageOptions[0]);
  const [page, setPage] = useState(1);
  const [maxHeight, setMaxHeight] = useState(250);
  const [query, setQuery] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.datatable',
  });

  const handleSelectAllItems = (event) => {
    setSelectedItems(event.target.checked
      ? rows.map((item) => item.id)
      : []);
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId),
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setQuery('');
    setPage(newPage + 1);

    loadData({
      page: newPage + 1,
      limit,
    });
  };

  const handleLimitChange = (event) => {
    setQuery('');
    const newLimit = event.target.value;
    setLimit(newLimit);
    setPage(1);
    loadData && loadData({
      page: 1,
      limit: newLimit,
    });
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    searchAction(event.target.value);
  };

  const handleQueryChangeByDate = (event) => {
    setQuery(event.target.value);
    searchActionByDate(event.target.value);
  };

  const selectedSomeItems =
    selectedItems &&
    selectedItems.length > 0 &&
    selectedItems.length < rows &&
    rows.length;
  const selectedAllItems =
    selectedItems && selectedItems.length === rows && rows.length;

  useEffect(() => {
    return history.listen(() => {
      setPage(1);
    });
  }, [history]);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const { top: elementTop } = containerRef.current.getBoundingClientRect();
    const height = windowHeight - elementTop - offsetTop;

    if (maxHeightFlag) {
      setMaxHeight(maxHeightValue);
    } else {
      setMaxHeight(height);
    }
  }, []);

  return (
    <Box mt={mt} className={classes.root}>

      <Filters
        filters={filters}
        query={query}
        searchAction={searchAction}
        handleQueryChange={handleQueryChange}
      />

      {!!searchActionByDate && <Paper className={classes.filterCard}>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={4} xs={6}>
            <DateControl
              fullWidth
              label={t('search')}
              size="small"
              variant="outlined"
              value={query}
              onChange={handleQueryChangeByDate}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              children="Eliminar filtro"
              onClick={() => onDeleteFilterDate()}
              size="large"
              style={{
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Paper>}

      <Card style={{
        borderRadius: '10px',
      }}>
        {title && (<>
          <CardHeader
            title={title}
            subheader={subTitle}
          />
          <Divider />
        </>)}
        <PerfectScrollbar>
          <Box>
            <Paper className={classes.root}>
              <TableContainer
                ref={containerRef}
                style={{
                  maxHeight: stickyHeader ? maxHeight : undefined,
                }}
                className={classes.customScrollBar}
              >
                <Table stickyHeader={stickyHeader} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {selectable && (
                        <TableCell padding="checkbox" size={size}>
                          <Checkbox
                            checked={selectedAllItems}
                            indeterminate={selectedSomeItems}
                            onChange={handleSelectAllItems}
                          />
                        </TableCell>
                      )}
                      {headers.map((head, index) => {
                        let cellValue = head;

                        if (typeof head === 'object') {
                          cellValue = head.label;
                        }

                        return (<TableCell
                          align="center"
                          children={cellValue}
                          key={index}
                          size={size}
                        />);
                      })}
                      {actions && (<TableCell
                        align="center"
                        children={t('actions')}
                        size={size}
                      />)}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows && rows.map((item, index) => {
                      const isItemSelected = selectedItems.includes(item.id);

                      return (
                        <StyledTableRow key={index} hover>
                          {selectable && (
                            <TableCell padding="checkbox" size={size}>
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleSelectOneItem(event, item.id)
                                }
                                value={isItemSelected}
                              />
                            </TableCell>
                          )}

                          {item.values.map((colValue, iCell) => {
                            let cell = {
                              children: colValue,
                            };

                            if (typeof colValue === 'object' && colValue !== null && !colValue.props) {
                              if (colValue.field) {
                                cell.children = (<DynamicForm
                                  fields={colValue.field}
                                  size="small"
                                />);
                              } else {
                                cell = {
                                  align: colValue.align || 'left',
                                  children: colValue.label,
                                  colSpan: colValue.colSpan,
                                };
                              }
                            }

                            return (
                              <TableCell
                                align="left"
                                style={item?.data?.style}
                                key={iCell}
                                size={size}
                                {...cell}
                              />
                            );
                          })}

                          {Array.isArray(actions) && (
                            <TableCell
                              align="center"
                              size={size}
                              style={item?.data?.style}
                            >
                              {actions.map((action, key) => {
                                const { fn = () => { } } = action;
                                let {
                                  color,
                                  disabled,
                                  hidden,
                                  icon,
                                  label,
                                  title,
                                } = action;

                                if (typeof disabled === 'function') {
                                  disabled = disabled(item, index);
                                }

                                if (disabled) {
                                  color = 'default';
                                } else if (typeof color === 'function') {
                                  color = color(item, index);
                                }

                                if (typeof hidden === 'function') {
                                  hidden = hidden(item, index);
                                }

                                if (typeof icon === 'function') {
                                  icon = icon(item, index);
                                }

                                if (typeof label === 'function') {
                                  label = label(item, index);
                                }

                                if (typeof title === 'function') {
                                  title = title(item, index);
                                }

                                const Title = title ? Tooltip : Fragment;
                                return !hidden ? (
                                  <Title key={key} title={title}>
                                    {action.label
                                      ? (<Button
                                        children={label}
                                        className={classes[`${color}Bg`]}
                                        disabled={disabled}
                                        onClick={() => fn(item, index)}
                                        size="small"
                                        style={{
                                          marginRight: '0.4em',
                                          marginLeft: '0.4em',
                                        }}
                                        startIcon={icon}
                                        variant="contained"
                                      />)
                                      : (<IconButton
                                        disabled={disabled}
                                        onClick={() => fn(item, index)}
                                        size="small"
                                        variant="outlined"
                                      >
                                        <SvgIcon
                                          children={icon}
                                          className={classes[color]}
                                          fontSize="small"
                                        />
                                      </IconButton>)}
                                  </Title>) : null;
                              })}
                            </TableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>

                  {footers && (<TableFooter>

                    {footerSeparator && (<TableRow>
                      <TableCell
                        className={classes.footerSeparator}
                        size="small" colSpan={6} />
                    </TableRow>)}

                    <TableRow>
                      {footers.map((footer, index) => {
                        let align = footer.align || 'center';
                        let cellValue = footer;

                        if (typeof footer === 'object' && !footer?.props) {
                          cellValue = footer.label || null;

                          if (footer.type === 'number') {
                            align = 'right';
                          }
                        }

                        return (<TableCell
                          align={align}
                          children={cellValue}
                          key={index}
                          size={size}
                        />);
                      })}
                    </TableRow>
                  </TableFooter>)}
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </PerfectScrollbar>

        {!removePagination && (
          <TablePagination
            backIconButtonText={t('previous_page')}
            component="div"
            count={total}
            labelRowsPerPage={t('rows_per_page')}
            nextIconButtonText={t('next_page')}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={perPageOptions}
          />
        )}
      </Card>
    </Box>
  );
}

DataTable.defaultProps = DEFAULT_PROPS;

DataTable.propTypes = PROP_TYPES;

export default DataTable;
