import Draggable from 'react-draggable';
import { Paper as MuiPaper } from '@material-ui/core';

function Paper(props) {
  return (<Draggable
    cancel={'[class*="MuiDialogContent-root"]'}
    children={<MuiPaper {...props} />}
    handle="#dialog-title"
  />);
}

export default Paper;
