import * as Yup from 'yup';

import MESSAGE from '../../constants/messages';

export const managersFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive(),
    company: Yup
      .number()
      .integer()
      .positive(),
    created_at: Yup
      .date(),
    group: Yup
      .number()
      .integer()
      .positive()
      .required(MESSAGE.REQUIRED)
      .typeError(MESSAGE.TYPE_ERROR_NUMBER),
    status: Yup
      .number()
      .oneOf([0, 1]),
    fields: Yup
      .object()
      .shape({
        email: Yup
          .string()
          .email(MESSAGE.EMAIL)
          .required(MESSAGE.REQUIRED),
        fullname: Yup
          .string()
          .required(MESSAGE.REQUIRED),
        kata_rol: Yup
          .string()
          .oneOf(['Administrador', 'Operativo', 'Supervisor'], MESSAGE.TYPE_ERROR_NUMBER)
          .required(MESSAGE.REQUIRED),
        user_kata: Yup
          .string()
          .required(MESSAGE.REQUIRED),
      }),
  });

export const userFormSchema = Yup
  .object()
  .shape({
    attributes: Yup
      .object()
      .shape({
        email: Yup
          .string()
          .email(MESSAGE.EMAIL)
          .lowercase()
          .trim()
          .required(MESSAGE.REQUIRED),
        phone_number: Yup
          .string(),
        name: Yup
          .string()
          .trim(),
        family_name: Yup
          .string()
          .trim(),
      }),
    role: Yup
      .number()
      .typeError(MESSAGE.TYPE_ERROR_NUMBER)
      .required(MESSAGE.REQUIRED),
    group: Yup
      .number()
      .typeError(MESSAGE.TYPE_ERROR_NUMBER)
      .required(MESSAGE.REQUIRED),
  });
