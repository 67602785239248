import {
  array,
  date,
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

import { getLocalDate } from '../../functions/date';

export const amlBlackListPeopleFisica = object().shape({
  nombres: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  apellidoPaterno: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  apellidoMaterno: string()
    .trim(),
});

export const amlBlackListPeopleMoral = object().shape({
  razonSocial: string()
    .trim()
    .required(MESSAGES.REQUIRED),
});

export const amlCountryFormSchema = object().shape({
  alias: string()
    .trim()
    .uppercase()
    .required(MESSAGES.REQUIRED),
  id: number(),
  name: string()
    .trim()
    .required(MESSAGES.REQUIRED),
  risk: string()
    .oneOf([
      'high',
      'low',
      'medium',
    ])
    .required(MESSAGES.REQUIRED),
});

export const pldCatalogAlertTypesFormSchema = object()
  .shape({
    company: number()
      .integer()
      .positive()
      .required(),
    config: array()
      .default([]),
    description: string()
      .required(MESSAGES.REQUIRED),
    id: number()
      .integer()
      .positive(),
    name: string()
      .required(MESSAGES.REQUIRED),
  });

export const pldInternalReportsSchema = object()
  .shape({
    id: number()
      .integer()
      .positive(),
    name: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    lastname: string()
      .default(' '),
    dob: date()
      .default(() => getLocalDate()),
    taxid: string()
      .uppercase()
      .trim()
      .max(13, MESSAGES.MAX_LENGTH)
      .min(12, MESSAGES.MIN_LENGTH),
    uid: string()
      .uppercase()
      .trim()
      .length(18, MESSAGES.LENGTH),
    reason: array()
      .of(number())
      .default([]),
    type: string()
      .required(MESSAGES.REQUIRED),
    note: string()
      .trim()
      .required(MESSAGES.REQUIRED),
  });

export const pldMatrixFormSchema = object()
  .shape({
    id: number()
      .integer()
      .positive()
      .optional(),
    client_type: number()
      .integer()
      .positive()
      .required(MESSAGES.REQUIRED),
    company: number()
      .integer()
      .positive()
      .optional(),
    config: object()
      .default({}),
    description: string()
      .required(MESSAGES.REQUIRED),
    name: string()
      .required(MESSAGES.REQUIRED),
  });

export const pldMatrixCategoryFormSchema = object()
  .shape({
    id: number()
      .integer()
      .positive()
      .optional(),
    max: number()
      .moreThan(0, MESSAGES.MORE_THAN)
      .typeError(MESSAGES.NUMBER),
    min: number()
      .moreThan(0, MESSAGES.MORE_THAN)
      .typeError(MESSAGES.NUMBER),
    name: string()
      .required(MESSAGES.REQUIRED),
    percentage: number()
      .moreThan(0, MESSAGES.MORE_THAN)
      .max(100, MESSAGES.MAX)
      .typeError(MESSAGES.NUMBER),
  });

export const pldMatrixCriteriaFormSchema = object()
  .shape({
    field: number()
      .integer()
      .positive()
      .required()
      .typeError(MESSAGES.TYPE_ERROR_NUMBER),
    comparator: number()
      .integer()
      .positive()
      .required(),
    value1: string()
      .required(),
    value2: string()
      .optional(),
  });

export const pldOperationsMasterFormSchema = object()
  .shape({
    alert: object()
      .default({}),
    company: number()
      .integer()
      .positive(),
    id: number()
      .integer()
      .positive(),
    instrument: number()
      .integer()
      .positive()
      .typeError(MESSAGES.INVALID)
      .required(MESSAGES.REQUIRED),
    nature: string()
      .oneOf(['Charge', 'Discharge'], MESSAGES.TYPE_ARRAY)
      .required(MESSAGES.REQUIRED),
    operation: number()
      .integer()
      .positive()
      .required(MESSAGES.REQUIRED),
    product: number()
      .integer()
      .positive()
      .typeError(MESSAGES.INVALID)
      .required(MESSAGES.REQUIRED),
  });
