import { useEffect } from 'react';
import path from 'path';

function useStyle(url) {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = path.join(process.env.PUBLIC_URL, url);
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [url]);
};

export default useStyle;
