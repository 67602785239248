import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';

function HtmlControl(props) {
  const {
    events,
    name,
    settings = {},
    value,
    onChange,
  } = props;
  const { prefix, suffix } = settings;
  let { options } = settings;

  if (typeof options === 'function') {
    options = options();
  }

  const handleChange = (event, editor) => {
    let data = editor.getData();
    options.forEach((option) => {
      data = data.replace(`${prefix}${option.name}${suffix}`, option.value);
    });

    const target = {
      name,
      value: data,
    };

    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  return (<CKEditor
    editor={ClassicEditor}
    data={value}
    onChange={handleChange}
  />);
}

HtmlControl.defaultProps = {
  events: {},
  onChange: () => { },
};

HtmlControl.propTypes = {
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  name: PropTypes.string,
  settings: PropTypes.shape({
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })),
      PropTypes.func,
    ]),
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default HtmlControl;
