import { Box, Typography } from '@material-ui/core';
import AceEditor from 'react-ace';

import PropTypes from 'prop-types';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

function CodeControl(props) {
  const {
    disabled,
    events,
    label,
    name,
    onChange,
    settings,
    value,
  } = props;

  const handleChange = (value) => {
    const target = {
      name,
      value,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  return (<fieldset style={{
    borderRadius: 5,
  }}>
    {label && <legend style={{
      marginLeft: 8,
    }}>
      <Typography color="textSecondary" variant="caption">
        <Box style={{
          marginLeft: 5,
          marginRight: 5,
        }}>
          {label}
        </Box>
      </Typography>
    </legend>}
    <Box m={1}>
      <AceEditor
        editorProps={{
          $blockScrolling: true,
        }}
        fontSize={14}
        highlightActiveLine={true}
        mode={settings.lang || 'html'}
        name={name}
        onChange={handleChange}
        readOnly={disabled}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: settings.lineNumbers === undefined
            ? true
            : settings.lineNumbers,
          tabSize: 2,
        }}
        showGutter={true}
        showPrintMargin={false}
        theme="monokai"
        value={value}
        height={settings?.height}
        width={'100%'}
      />
    </Box>
  </fieldset>);
}

CodeControl.defaultProps = {
  disabled: false,
  onChange: () => { },
  settings: {},
};

CodeControl.propTypes = {
  disabled: PropTypes.bool,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  settings: PropTypes.shape({
    height: PropTypes.number,
    lang: PropTypes.oneOf([
      'html',
      'json',
    ]),
    lineNumbers: PropTypes.bool,
  }),
};

export default CodeControl;
