import { lazy } from 'react';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')), 
};

const internalRoutes = {
  path: '/internal',
  routes: [
    {
      exact: true,
      path: '/internal/templates/files/:templateId',
      component: lazy(() => import('views/Settings/Templates/Notifications/Edit/Preview')),
    },
    error404,
  ],
};

export default internalRoutes;
