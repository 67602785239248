import Dynamicore, { SERVICES } from 'services/dynamicore';
import { dynamicSort } from 'utils/arrays';

import {
  PEOPLE_TYPES_FAILURE,
  PEOPLE_TYPES_SUCCESS,
  PEOPLE_TYPES_FETCHING,
  PEOPLE_TYPES_SEARCH,
} from '../constants/peopleTypes';

export const peopleTypesFetching = () => {
  return {
    type: PEOPLE_TYPES_FETCHING,
  };
};

export const peopleTypesSuccess = (data) => {
  return {
    type: PEOPLE_TYPES_SUCCESS,
    ...data,
  };
};

export const peopleTypesFailure = (error) => {
  return {
    type: PEOPLE_TYPES_FAILURE,
    error,
  };
};

export const peopleTypesSearch = (query) => {
  return {
    type: PEOPLE_TYPES_SEARCH,
    query,
  };
};

export const peopleTypesRequest = (filters) => {
  return async (dispatch) => {
    dispatch(peopleTypesFetching());
    try {
      const { values = [], total } = await Dynamicore(SERVICES.PEOPLE_TYPES)
        .get(filters);
      dispatch(peopleTypesSuccess({
        items: values.sort(dynamicSort('name')),
        total,
      }));
    } catch (error) {
      dispatch(peopleTypesFailure(error.message));
      throw error;
    }
  };
};
